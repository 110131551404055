<section class="module_singleText">
  <div class="module_singleText_wrapper container flex">
    <div class="text_side">
      <div class="header_div blue">
        <h1>Pricing</h1>
        <h2><strong>The cost of your PA</strong></h2>
        <h2>PA representation on your calls is charged at 2p per second*</h2>
        <p>(Billed in 30 second increments)</p>
        <h2>*Calls lasting under 10 seconds are not charged for.</h2>
      </div>
      <h2 style="text-align: center;">You are minutes away from having your own receptionist.</h2>
      <h2 style="text-align: center;">Select your new unique number from the list below</h2>
    </div>
  </div>
</section>
<section class="module_text-table">
  <div class="module_text-table--inner flex">
    <h1>The Cost of your Number</h1>
    <div class="charges">
      <h2>Monthly Charge</h2>
      <div class="flex spaced">
        <p>Standard</p>
        <p><span>£10.00</span></p>
      </div>
      <div class="flex spaced">
        <p>Silver</p>
        <p><span>£12.50</span></p>
      </div>
      <div class="flex spaced">
        <p>Gold</p>
        <p><span>£20.00</span></p>
      </div>
      <div class="flex spaced">
        <p>Platinum</p>
        <p><span>£30.00</span></p>
      </div>
    </div>
    <div class="details flex">
      <div class="prefix">
        <h2>Prefix</h2>
        <p>01/02</p>
        <p>03</p>
        <p>08</p>
      </div>
      <div class="ppm">
        <h2>Pence Per Minute</h2>
        <p>1.5ppm</p>
        <p>1.0ppm</p>
        <p>7.0ppm</p>
      </div>
    </div>
  </div>
</section>
