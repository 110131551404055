import { NgModule, ModuleWithProviders } from "@angular/core";
import { PricingComponent } from "./components/pricing/pricing.component";
import { FaqsComponent } from "./components/faqs/faqs.component";
import { MeetTheTeamComponent } from "./components/meet-the-team/meet-the-team.component";
import { ServiceDescriptionComponent } from "./components/service-description/service-description.component";
import { Marketing247Component } from "./marketing-247.component";
import { RegistrationModule } from "../registration/registration.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";

const COMPONENTS = [
  Marketing247Component,
  FaqsComponent,
  PricingComponent,
  MeetTheTeamComponent,
  ServiceDescriptionComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RegistrationModule, NgbModule],
  exports: [Marketing247Component],
  providers: [],
})
export class Marketing247Module {
  static forRoot(): ModuleWithProviders<Marketing247Module> {
    return {
      ngModule: Marketing247Module,
      providers: [],
    };
  }
}
