import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "action-coach-accordian-item",
  templateUrl: "./action-coach-accordian-item.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ActionCoachAccordianItemComponent {
  constructor() {}

  showPanel = false;
}
