import { Component, OnInit } from "@angular/core";
import { MODE_UNKNOWN, getMode } from "./mode-determinent";

@Component({
  selector: "r247-mode-checker",
  templateUrl: "./mode-checker.comonent.html",
})
export class ModeCheckerComponent implements OnInit {
  constructor() {}

  mode = MODE_UNKNOWN;

  ngOnInit(): void {
    this.mode = getMode();
  }
}
