<h2 class="text-center">Top Up Your Account</h2>
<r247-cost-breakdown 
    [showFooter]="false" 
    [category]="category"
    [prefix]="prefix"
    [contacts]="contacts">
</r247-cost-breakdown>
<div class="card mb-2 mt-2">
    <div class="card-header" class="card-header">Top Up</div>
    <div class="card-body">
        <div class="col-12 mb-3">
            Please top up by at least <strong>£{{minimumTopUp}}</strong>.
        </div>
        <r247-top-up-form 
        [(topUpForm)]="topUpForm" 
        [minimumTopUp]="minimumTopUp"
        (autoTopUpToggled)="autoTopUpToggled()"></r247-top-up-form>
    </div>
</div>
<div class="card mb-2">
    <div class="card-header">Billing Address</div>
    <div class="card-body">
        <form [formGroup]="billingAddressForm">                      
          <r247-address-picker [searchState]="'show-address'" addressSearchLabel="Billing Address" searchAgainLabel="Change" [(address)]="billingAddressForm"></r247-address-picker>
        </form>
    </div>
</div>
<div class="card">
    <div class="card-header">Card Details</div>
    <div class="card-body">
        <r247-card-details [(cardDetailsForm)]="cardDetailsForm" [forceSaveCardDetails]="isAutoTopUp"></r247-card-details>
    </div>
</div>
<r247-stepper-buttons nextCaption="Register" (previousStep)="onPreviousStepClick()" (nextStep)="onStepComplete()"></r247-stepper-buttons>