import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

import { AuthService } from "../../@core/backend/receptionist/services/auth.service";
@Component({
  selector: "r247-auth",
  styleUrls: ["./auth.component.scss"],
  template: `
    <r247-nav-bar [showAccountLink]="false"></r247-nav-bar>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class R247AuthComponent implements OnInit {
  isAuthenticated = false;

  constructor(protected location: Location, private authService: AuthService) {}

  ngOnInit(): void {}
}
