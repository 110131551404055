<div class="login-form mb-3">
  <div class="alert alert-danger" *ngIf="error?.length >= 1" role="alert">  
    {{error}}
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="card">
      <div class="card-header">Login</div>
      <div class="card-body">
        
          <div class="row mb-1">
            <div class="col-xs-12 col-md-3">
              <label for="input-email">Email address:</label>
            </div>
            <div class="col-xs-12 col-md-9">
              <input type="text" class="form-control" id="input-email" formControlName="email" [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Email address">
              <r247-validation-message errorMessage="Please enter a valid email address" [showError]="email?.hasError('pattern') && email.touched"></r247-validation-message>
              <r247-validation-message errorMessage="Please enter your email address" [showError]="email?.errors?.required && email.touched"></r247-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <label for="input-password">Password:</label>
            </div>
            <div class="col-xs-12 col-md-9">
              <input type="text" class="form-control" id="input-password" formControlName="password" type="password" placeholder="Password">
              <r247-validation-message errorMessage="Please enter your password" [showError]="password?.errors?.required && password.touched"></r247-validation-message>
            </div>
          </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <a href="#" class="btn btn-secondary btn-block" role="button" routerLink="/password/forgot">Forgot Password?</a>
          </div>
          <div class="mt-1 mt-md-0 col-xs-12 col-md-6">
            <button type="submit" class="btn btn-primary btn-block">Log In</button>
          </div>
        </div>
      </div>
  </div>
</form>
</div>
<section class="another-action text-center" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="/register/number">Register</a>
</section>