import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "r247-register-number-chooser",
  templateUrl: "./register-number-chooser.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247RegisterNumberChooserComponent {
  constructor(protected router: Router, private route: ActivatedRoute) {}

  goToRegister(selectedNumber) {
    var url = `/register/account?category=${selectedNumber.category}&prefix=${selectedNumber.prefix}&range=${selectedNumber.range}&number=${selectedNumber.number}`;

    let resellerId = this.route.snapshot.queryParamMap.get("reseller");
    if (resellerId != null) url += `&reseller=${Number(resellerId)}`;

    return this.router.navigateByUrl(url);
  }
}
