import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "r247-faqs",
  templateUrl: "./faqs.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247FaqsComponent {
  constructor() {}

  receptionFaqs = [
    {
      question: "Who will answer my calls?",
      answer:
        "Your dedicated receptionist sits within a small team of highly trained receptionists based in our Peterborough Call Centre. We are a solely UK based service, providing 24/7 reception services in your company name. The team are friendly and professional, ensuring your customers feel as if they are speaking to a member of your team.",
    },
    {
      question: "How do I choose a phone number and how does it work?",
      answer:
        "247Receptionist offers a choice of any UK dial code or non-geographical phone number which become your companies own phone number. You are free to publish this number as your main business number or as an additional number [for specific marketing purposes for example].",
    },
    {
      question:
        "I already have a phone number that my customers use, what happens then?",
      answer:
        "Most clients divert their existing business number on overflow as a safety net to catch the calls they had been missing. This is highly effective in maximising opportunity and prevents wasted advertising budget. The speed of messaging puts you in front of your customer before they have had the time to call your competitors.",
    },
    {
      question: "How will I know that I have missed a call?",
      answer:
        "You can choose any combination of methods to receive and access message details. We send messages to you / your staff by text message, by email and via our online portal, where communication is stored permanently for retrieval at any time. The data includes the amount of time your receptionist has taken to represent your business on every call to aid reconciliation. All data are available for download.",
    },
    {
      question:
        "How will my receptionist know how to answer for you and what to say?",
      answer:
        "When your customer calls, your company screen will open for your receptionist. It contains all the information required to represent your business professionally. Your receptionist will use your customised greeting to answer your customer calls and will represent you professionally. Your customer will know they have reached your business and leave the caller confident that you will respond when you are able.",
    },
  ];

  accountFaqs = [
    {
      question: "Is there a fee to sign up or register?",
      answer:
        "There are no sign up or registration fees. Simply pay the first month charge for your chosen number and purchase the credit you require.",
    },
    {
      question: "What happens if I run out of credit?",
      answer:
        "When your balance drops below £50 and you do not have auto top ups enabled, we will email you to let you know. If you do not top up and your balance drops below zero, your account will be suspended and we will stop handling your calls until a top up is made. After thirty days your account will be deactivated and your phone number handed back to the provider.",
    },
    {
      question: "Am I tied in to a long contract. How do I cancel if I want?",
      answer:
        "Change in life and business circumstance is a constant. If for whatever reason the service is no longer required, simply cease to use it and not purchase any further credit, as there is no contract term to be tied in to. Your chosen phone number can be retained and used without use of the receptionist service, for as long as you require, by topping up credit for your phone number usage only.",
    },
  ];
}
