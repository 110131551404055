import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'lessThanOnePence' })
export class LessThanOnePencePipe implements PipeTransform {
    transform(value: number) {

        if (value === 0)
            return `£${0.00}`;

        const threshold = 0.01;

        var amount = value;
        if (Math.sign(value) === -1 || Math.sign(value) === -0) 
            amount = value * -1;

        return (amount < threshold)
            ? `<£${threshold}`
            : ` £${this.roundValue(amount)}`;
    }

    roundValue(value) {
        return (Math.round(value * 100) / 100).toFixed(2);
    }
}