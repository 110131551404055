import { NgModule, ModuleWithProviders } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MarketingRoutingModule } from "./marketing-routing.module";
import { ComponentsModule } from "../@components/components.module";
import { R247MarketingComponent } from "./marketing.component";
import { R247MarketingScreenComponent } from "./screens/marketing/marketing-screen.component";
import { RegistrationModule } from "../registration/registration.module";
import { R247MarketingHeaderComponent } from "./components/header/header.component";
import { R247MarketingHighlightsComponent } from "./components/highlights/highlights.component";
import { R247MarketingPricingComponent } from "./components/pricing/pricing.component";
import { R247FaqsComponent } from "./components/faqs/faqs.component";
import { R247MarketingRegisterComponent } from "./components/marketing-register/marketing-register.component";
import { CategoryFriendlyNamePipe } from "./components/pricing/category-friendly-name.pipe";
import { Marketing247Module } from "../marketing247/marketing-247.module";
import { ActionCoachAccordianComponent } from "./components/action-coach-accordian/action-coach-accordian.component";
import { ActionCoachAccordianItemComponent } from "./components/action-coach-accordian/action-coach-accordian-item.component";

const COMPONENTS = [
  R247MarketingComponent,
  R247MarketingScreenComponent,
  R247MarketingHeaderComponent,
  R247MarketingHighlightsComponent,
  R247MarketingPricingComponent,
  R247FaqsComponent,
  R247MarketingRegisterComponent,
  CategoryFriendlyNamePipe,
  ActionCoachAccordianComponent,
  ActionCoachAccordianItemComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    MarketingRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    NgbModule,
    Marketing247Module,
    RegistrationModule,
  ],
  exports: [],
  providers: [],
})
export class MarketingModule {
  static forRoot(): ModuleWithProviders<MarketingModule> {
    return {
      ngModule: MarketingModule,
      providers: [],
    };
  }
}
