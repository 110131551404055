import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'categoryFriendlyName' })
export class CategoryFriendlyNamePipe implements PipeTransform {
    transform(value: string) {

        if (value.length <= 0)
            return value;

        return value.substring(0, 1).toUpperCase() + value.substring(1);
    }
}