import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";

@Injectable()
export class StatementsService {
  constructor(private api: HttpService) {}

  private readonly apiController: string = "statements";

  getStatements(): Observable<[]> {
    return this.api.get(`${this.apiController}`);
  }

  get(statementNumber: number): Observable<any> {
    return this.api.get(`${this.apiController}/${statementNumber}`, {
      responseType: "blob",
    });
  }
}
