import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { R247AreaSearchModalComponent } from "../area-search/area-search-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { R247CategoriesModalComponent } from "../categories-modal/categories-modal.component";

@Component({
  selector: "r247-number-search",
  templateUrl: "./number-search.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247NumberSearchComponent {
  constructor(private modalService: NgbModal) {}

  @Input() prefix: string = "";
  @Input() prefixes = [];

  @Input() category: string = "";
  @Input() categories = [];

  @Input() selectedNumber = "";

  @Output() numberSelected = new EventEmitter();
  @Output() numbersSearched = new EventEmitter();

  currentPage = 1;
  @Output() previousPage = new EventEmitter<number>();
  @Output() nextPage = new EventEmitter<number>();

  @Input() numbers: [];
  @Input() totalNumbers: number = 0;
  @Input() searchingNumbers = true;

  get selectedPrefixDisplay() {
    let items = this.prefixes.filter((x) => x.code === this.prefix);

    return items.length >= 1 ? items[0].description : "No Prefix Selected";
  }

  get totalPages() {
    return Math.round(this.totalNumbers / 5);
  }

  searchNumbers() {
    this.currentPage = 1;
    this.numbersSearched.emit({
      category: this.category,
      prefix: this.prefix,
    });
  }

  selectNumber(number: any) {
    this.selectedNumber = `${number.prefix} ${number.range} ${number.number}`;

    this.numberSelected.emit({
      category: this.category,
      prefix: number.prefix,
      range: number.range,
      number: number.number,
    });
  }

  showSearchAreaModal() {
    var modal = this.modalService.open(R247AreaSearchModalComponent, {
      scrollable: true,
      size: "lg",
    });

    modal.componentInstance.prefix = this.prefix;

    modal.result.then((x) => {
      this.prefix = x.code;
      this.searchNumbers();
    });

    modal.componentInstance.prefixes = this.prefixes;
  }

  showCategoriesModal() {
    var modal = this.modalService.open(R247CategoriesModalComponent, {
      scrollable: true,
      size: "lg",
    });

    modal.componentInstance.category = this.category;

    modal.result.then((x) => {
      this.category = x;
      this.searchNumbers();
    });

    modal.componentInstance.categories = this.categories;
  }

  onNextPage() {
    this.currentPage += 1;
    this.nextPage.emit(this.currentPage);
  }

  onPreviousPage() {
    this.currentPage -= 1;
    this.nextPage.emit(this.currentPage);
  }
}
