import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from "@angular/core";

@Component({
  selector: "r247-pills",
  templateUrl: "./pills.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247PillsComponent {
  @Input() pills = [];
}
