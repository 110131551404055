<div class="card-footer">
  <div class="mb-1">
    <ng-select 
      [items]="prefixes"
      notFoundText="No Prefixes Found"
      [clearable]="false"
      bindLabel="description"
      bindValue="code"
      [(ngModel)]="prefix"
      (ngModelChange)="searchNumbers()"
      [searchFn]="searchPrefixes">
    </ng-select>
  </div>
  <div class="mb-1">
    <ng-select 
      [items]="categories"
      notFoundText="No Categories Found"
      [clearable]="false"
      bindLabel="category"
      bindValue="category"
      [(ngModel)]="category"
      (ngModelChange)="onCategoryChange($event)">
    </ng-select>
  </div>
</div>
<div class="list-group list-group-flush numbers-list">
      <div *ngIf="searchingNumbers || (numbers === null || numbers?.length <= 0)" class="text-center pt-3">
        <h3 *ngIf="searchingNumbers">Searching...</h3>
        <h3 *ngIf="searchingNumbers === false && (Numbers === null || Numbers?.length <= 0)">No Numbers Found</h3>
      </div>
      <div 
        *ngFor="let number of numbers"
        class="list-group-item text-center phone-number-item"
        [ngClass]="{'selected-number': number.prefix + ' ' + number.range + ' ' + number.number === selectedNumber}"
        (click)="selectNumber(category, number)">
        {{number.prefix}} {{number.range}} {{number.number}}
      </div>
</div>
