import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'r247-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247StepperButtonsComponent {
  constructor() { }

  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  @Input() showPreviousButton = true;
  @Input() nextCaption = 'Next';
}