import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { PricingService } from "../../../@core/backend/receptionist/services/pricing-service";
import { LOW_BALANCE_THRESHOLD } from "../../../@core/interfaces/receptionist/payment";

@Component({
  selector: "r247-cost-breakdown",
  templateUrl: "./cost-breakdown.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247CostBreakdownComponent implements OnInit, OnChanges {
  constructor(private pricing: PricingService) {}

  @Input() showFooter = false;
  @Input() footerButtonCaption = "Reserve My Phone Number";

  showTextCharges = false;

  @Input() category = "";
  @Input() prefix = "";
  @Input() contacts = [];

  @Output() footerButtonClick = new EventEmitter();

  callCharge = "0p per minute";
  receptionistCharge = "0p per second";
  serviceCharge = "£0 per month";
  serviceChargeThisMonth = "£0 this month (deducted today)";

  lowBalanceThreshold = LOW_BALANCE_THRESHOLD;

  ngOnInit(): void {
    this.updateCallPricing();

    this.showTextCharges = this.contacts.filter((x) => x.sendSms).length >= 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateCallPricing();
  }

  updateCallPricing(): void {
    this.pricing
      .getPricing(this.prefix, this.category)
      .subscribe((response) => {
        this.callCharge = response.callCost;
        this.receptionistCharge = response.receptionistCost;
        this.serviceCharge = response.serviceCharge;
        this.serviceChargeThisMonth = response.serviceChargeThisMonth;
      });
  }

  onFooterButtonClick(): void {
    this.footerButtonClick.emit();
  }
}
