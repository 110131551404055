import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "r247-info-icon-modal",
  templateUrl: "./info-icon-modal.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247InfoIconModalComponent {
  constructor() {}
  @Input() caption = "";
}
