<div class="mt-5" *ngIf="validatingToken">
  <div class="col-12 text-center"><h1>Checking reset password Link...</h1></div>
</div>
<div class="mt-3" *ngIf="!validatingToken && !allowReset">
  <h1>Your password reset link has expired.</h1>
  <a routerLink="/password/forgot" class="btn btn-primary">Request New Link</a>
</div>
<div class="reset-password-form" *ngIf="!validatingToken && allowReset">
  <form *ngIf="status === 0" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
  <div class="card">
    <div class="card-header">
      Reset Password
    </div>
    <div class="card-body">
        <div class="row mb-1">
          <div class="col-xs-12 col-md-4">
            <label for="input-password">Password:</label>
          </div>
          <div class="col-xs-12 col-md-8">
            <input  id="input-password" formControlName="password" type="password" class="form-control" placeholder="Password">
            <r247-validation-message [showError]="password?.errors?.required && password.touched" errorMessage="Please enter a password"></r247-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <label for="input-re-password">Confirm Password:</label>
          </div>
          <div class="col-xs-12 col-md-8">
            <input  id="input-re-password" class="form-control" formControlName="confirmPassword" type="password" placeholder="Password">
            <r247-validation-message errorMessage="Please confirm your password" [showError]="confirmPassword?.errors?.required && confirmPassword.touched"></r247-validation-message>
            <r247-validation-message errorMessage="Password and confirm password does not match!" [showError]="password.value != confirmPassword.value && confirmPassword.touched && password.touched"></r247-validation-message> 
          </div>
        </div>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary float-right" [disabled]="submiting">
        <span *ngIf="!submiting">Reset password</span>
        <span *ngIf="submiting">Reseting password...</span>
      </button>
    </div>
  </div>
</form>
<div *ngIf="status === 1" class="card">
  <div class="card-header">
    Reset Password
  </div>
  <div class="card-body">
      <p>Your password has been reset.</p>
  </div>
  <div class="card-footer">
    <a routerLink="/login" class="btn btn-primary float-right">Login</a>
  </div>
</div>
</div>