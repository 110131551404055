import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "action-coach-accordian",
  templateUrl: "./action-coach-accordian.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ActionCoachAccordianComponent {
  constructor() {}

  @Input() items = [];
}
