import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

@Injectable()
export class CardDetailsFormBuilder {
  constructor(private fb: FormBuilder) {}

  private cardholderName = '';

  withCardholderName(cardholderName:string)
  {
    this.cardholderName = cardholderName;
    return this;
  }

  private cardNumber = '';

  withCardNumber(cardNumber:string)
  {
    this.cardNumber = cardNumber;
    return this;
  }

  private cvc = '';

  withCvc(cvc:string)
  {
    this.cvc = cvc;
    return this;
  }

  private expiryMonth = '';

  withExpiryMonth(expiryMonth:string)
  {
    this.expiryMonth = expiryMonth;
    return this;
  }

  private expiryYear = '';

  withExpiryYear(expiryYear:string)
  {
    this.expiryYear = expiryYear;
    return this;
  }

  private cardDescription = '';

  withCardDescription(cardDescription:string)
  {
    this.cardDescription = cardDescription;
    return this;
  }

  private saveCardDetails = false;

  enableSaveCardDetails()
  {
    this.saveCardDetails = true;
    return this;
  }

  disableSaveCardDetails()
  {
    this.saveCardDetails = false;
    return this;
  }

 create() {
    return this.fb.group({
      name: [this.cardholderName, [Validators.required]],
      number: [this.cardNumber, [Validators.required, Validators.minLength(16),Validators.maxLength(16)]],
      cvc: [this.cvc, [Validators.required, Validators.minLength(3),Validators.maxLength(3)]],
      expiryMonth: [this.expiryMonth, [Validators.required,Validators.minLength(2),Validators.maxLength(2)]],
      expiryYear: [this.expiryYear, [Validators.required,Validators.minLength(2),Validators.maxLength(2)]],
      saveCardDetails: [this.saveCardDetails, []],
      cardDescription: [this.cardDescription, []]
    });
  }
}