import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Observable } from "rxjs";
import { PhoneNumbersService } from "../../../@core/backend/receptionist/services/phone-number.service";
import { numbersTemplate } from "./number-list-card/numbers-list-template";

@Component({
  selector: "r247-number-chooser",
  templateUrl: "./number-chooser.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247NumberChooserComponent implements OnInit {
  constructor(private phoneNumberService: PhoneNumbersService) {}

  @Output() numberConfirmed = new EventEmitter();

  get isNumberChosen() {
    return this.selectedVirtualNumber != "";
  }

  category = "";
  selectedVirtualNumber = "";
  prefix = "";
  range = "";
  number = "";
  numbersTemplate = numbersTemplate;

  selectedLocalPrefix = numbersTemplate.defaultLocalPrefix;
  selectedNationalPrefix = numbersTemplate.defaultNationalPrefix;
  selectedFreephonePrefix = numbersTemplate.defaultFreephonePrefix;

  selectedLocalCategory = numbersTemplate.localCategories[0];
  selectedNationalCategory = numbersTemplate.nationalCategories[0];
  selectedFreephoneCategory = numbersTemplate.freephoneCategories[0];

  LOCAL_NUMBERS = 0;
  NATIONAL_NUMBERS = 1;
  FREEPHONE_NUMBERS = 2;

  numberType = this.LOCAL_NUMBERS;

  localNumbers = [];
  nationalNumbers = [];
  nationalNumbers1 = [];
  freephoneNumbers = [];

  searchingLocalNumbers = true;
  searchingNationalNumbers = true;
  searchingFreephoneNumbers = true;

  ngOnInit(): void {
    this.getLocalNumbers(this.selectedLocalPrefix, this.selectedLocalCategory);

    this.getNationalNumbers(
      this.selectedNationalPrefix,
      this.selectedNationalCategory
    );

    this.getFreephoneNumbers(
      this.selectedFreephonePrefix,
      this.selectedFreephoneCategory
    );
  }

  onLocalNumbersSearched(numberArgs) {
    this.getLocalNumbers(numberArgs.prefix, numberArgs.category);
  }

  onNationalNumbersSearched(numberArgs) {
    this.getNationalNumbers(numberArgs.prefix, numberArgs.category);
  }

  onFreephoneNumbersSearched(numberArgs) {
    this.getFreephoneNumbers(numberArgs.prefix, numberArgs.category);
  }

  getLocalNumbers(prefix, category) {
    this.searchingLocalNumbers = true;

    this.getNumbers(prefix, category).subscribe(
      (response) => {
        this.searchingLocalNumbers = false;
        this.localNumbers = response.data;
        this.setSelectedValues(prefix, category);
      },
      (error) => (this.searchingLocalNumbers = false)
    );
  }

  getNationalNumbers(prefix, category) {
    this.searchingNationalNumbers = true;

    this.getNumbers(prefix, category).subscribe(
      (response) => {
        this.searchingNationalNumbers = false;
        this.nationalNumbers = response.data;
        this.setSelectedValues(prefix, category);
      },
      (error) => (this.searchingNationalNumbers = false)
    );
  }

  getFreephoneNumbers(prefix, category) {
    this.searchingFreephoneNumbers = true;
    this.getNumbers(prefix, category).subscribe(
      (response) => {
        this.searchingFreephoneNumbers = false;
        this.freephoneNumbers = response.data;
        this.setSelectedValues(prefix, category);
      },
      (error) => (this.searchingFreephoneNumbers = false)
    );
  }

  getNumbers(prefix: string, category: string): Observable<any> {
    return this.phoneNumberService.getNumbers(prefix, category).pipe();
  }

  setNumberValues(numberArgs) {
    this.setSelectedValues(numberArgs.prefix, numberArgs.category);

    this.category = numberArgs.category;
    this.selectedVirtualNumber = `${numberArgs.prefix} ${numberArgs.range} ${numberArgs.number}`;

    this.prefix = numberArgs.prefix;
    this.range = numberArgs.range;
    this.number = numberArgs.number;
  }

  setSelectedValues(prefix, category) {
    switch (this.numberType) {
      case this.LOCAL_NUMBERS:
        this.selectedLocalPrefix = prefix;
        this.selectedLocalCategory = category;
        break;
      case this.NATIONAL_NUMBERS:
        this.selectedNationalPrefix = prefix;
        this.selectedNationalCategory = category;
        break;
      case this.FREEPHONE_NUMBERS:
        this.selectedFreephonePrefix = prefix;
        this.selectedFreephoneCategory = category;
        break;
    }
  }

  onNumberConfirmClick(number) {
    this.numberConfirmed.emit({
      category: number.category,
      prefix: number.prefix,
      range: number.range,
      number: number.number,
    });
  }
}
