import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FranchiseApi } from "../api/franchise.api";

@Injectable()
export class FranchiseService {
  constructor(private api: FranchiseApi) {}

  isFranchisor(): Observable<boolean> {
    return this.api.isFranchisor(sessionStorage.getItem("seller_code"));
  }

  getResellers(): Observable<any[]> {
    return this.api.getResellers(sessionStorage.getItem("seller_code"));
  }

  get(): Observable<any> {
    return this.api.get(sessionStorage.getItem("seller_code")).pipe(
      map((config) => {
        return config;
      })
    );
  }

  calculateRevenueForReseller(resellerId: number): Observable<any> {
    return this.api.calculateRevenueForReseller(resellerId);
  }

  calculateRevenuePerReseller(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.calculateRevenuePerReseller(periodStart, periodEnd);
  }

  topResellersByRevenue(periodStart: Date, periodEnd: Date): Observable<any> {
    return this.api.topResellersByRevenue(periodStart, periodEnd);
  }

  topResellersByClientCount(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.topResellersByClientCount(periodStart, periodEnd);
  }

  countClients(): Observable<any> {
    return this.api.countClients();
  }

  countResellers(): Observable<any> {
    return this.api.countResellers();
  }

  calculateRevenuePerMonth(): Observable<any> {
    return this.api.calculateRevenuePerMonth();
  }

  calculateUnRealisedRevenue(): Observable<number> {
    return this.api.calculateUnRealisedRevenue();
  }

  exportRevenuePerReseller(periodStart: Date, periodEnd: Date) {
    return this.api.exportRevenuePerReseller(periodStart, periodEnd);
  }
}
