import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/backend/receptionist/services/auth.service';
@Component({
  selector: 'r247-logout',
  templateUrl: './logout.component.html',
})
export class R247LogoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.authService.logout();
    this.router.navigate(['auth/login']);
  }
}
