import { Component, OnInit } from "@angular/core";

@Component({
  selector: "r247-marketing",
  templateUrl: "./marketing.component.html",
})
export class R247MarketingComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    if (sessionStorage.getItem("seller_code") === "247receptionist")
      window.location.href = "https://247receptionist.co.uk";
  }
}
