<h2 class="text-center">Set Up Your Account</h2>
    <form [formGroup]="accountStep">
      <div class="row mb-2">
        <div class="col-12">
          <div class="card">
            <div class="card-header">User Account</div>
            <div class="card-body">
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-email">Full Name</label>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-4">
                  <input class="form-control" placeholder="Your full name" formControlName="name" type="text" />
                  <r247-validation-message errorMessage="Please enter your Name" [showError]="name?.errors?.required && name.touched">
                  </r247-validation-message>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-email">Phone Number</label>
                </div>
                <div class="col-xs-12 col-md-9 col-lg-4">
                  <input formControlName="clientContactNumber" class="form-control" type="tel" (keydown.Space)="$event.preventDefault();" id="user-contact-number" placeholder="Contact Number" />
                    <r247-validation-message errorMessage="Please enter a contact number" [showError]="clientContactNumber?.errors?.required && clientContactNumber.touched"></r247-validation-message>
                    <r247-validation-message errorMessage="Please enter a valid phone number" [showError]="clientContactNumber?.hasError('pattern') && clientContactNumber.touched"></r247-validation-message>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-email">Email address</label>
                </div>
                <div class="col-xs-12 col-md-9 col-lg-4">
                  <input class="form-control" id="user-email" placeholder="Email address for accessing the portal" formControlName="email" type="email" (blur)="validateEmailExists($event)" />
                    <r247-validation-message errorMessage="Please a valid email" [showError]="email?.hasError('pattern') && email.touched"></r247-validation-message>
                    <r247-validation-message errorMessage="Please enter your email address" [showError]="email?.errors?.required && email.touched"></r247-validation-message>
                    <r247-validation-message errorMessage="This email address is already linked to an account" [showError]="email?.hasError('exists') && email.touched"></r247-validation-message>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-password">Password</label>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-4">
                  <div class="input-group">
                    <input id="input-password" [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="password" placeholder="Enter a password of at least 8 characters" />
                    <div class="input-group-append">
                      <button class="btn btn-secondary" type="button" (click)="showPassword = !showPassword">
                        <span *ngIf="!showPassword">Show</span>
                        <span *ngIf="showPassword">Hide</span>
                      </button>
                    </div>
                  </div>
                  <r247-validation-message errorMessage="Please enter a password" [showError]="password?.errors?.required && password.touched"></r247-validation-message>
                  <r247-validation-message errorMessage="Password must be at least 8 characters" [showError]="password?.errors?.minlength && password.touched"></r247-validation-message>
                </div>
              </div>
              <div class="row mt-4 mb-1" *ngIf="isFranchisor$ | async">
                <div class="col-xs-12 col-md-3">
                  <label for="input-franchisee">{{franchiseeCaption$ | async}}</label>
                </div>
                <div class="col-xs-12 col-md-9 col-lg-4">
                  <ng-select 
                    [disabled]="allowFranchiseeSelection"
                    formControlName="franchisee"
                    [items]="franchisees"
                    notFoundText="No Franchisees Found"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="id">
                    <!-- [(ngModel)]="selectedFranchisee"
                    (ngModelChange)="onFranchiseeChange($event)"> -->
                  </ng-select>
                </div>
              </div>
              <div class="row mt-4 mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-sign-up-code">Promotional Code</label>
                </div>
                <div class="col-xs-12 col-md-9 col-lg-4">
                  <input class="form-control" id="input-sign-up-code" placeholder="Enter your discount or activation code if you have one" formControlName="signUpCode" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Company</div>
            <div class="card-body">
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-email">Company Name</label>
                </div>
                <div class="col-xs-12 col-md-9 col-lg-4">
                  <input formControlName="clientName" class="form-control" type="text" id="user-company-name" placeholder="Your registered company name" />
                  <r247-validation-message errorMessage="Please enter your company name" [showError]="clientName?.errors?.required && clientName.touched"></r247-validation-message>
                </div>
              </div>
              <r247-address-picker [searchState]="addressSearchState" addressSearchLabel="Registered Company Address" [(address)]="address"></r247-address-picker>
            </div>
          </div>
          </div>
        </div>
    </form>
    <div class="row">
      <div class="col-12">
        <r247-stepper-buttons [showPreviousButton]="false" (nextStep)="onNextStepClick()"></r247-stepper-buttons>
      </div>
    </div>