<div class="row p-0">
  <div class="col-12 p-0">
    <div class="marketing-section hero">
      <r247-marketing-header></r247-marketing-header>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 p-0">
    <div class="marketing-section primary">
      <r247-marketing-highlights></r247-marketing-highlights>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 p-0">
    <div class="marketing-section secondary">
      <r247-marketing-pricing></r247-marketing-pricing>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 p-0">
    <div class="marketing-section secondary pb-12">
      <r247-marketing-register></r247-marketing-register>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 p-0">
    <div class="marketing-section primary">
      <r247-faqs></r247-faqs>
    </div>
  </div>
</div>
