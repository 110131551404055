import { Component } from "@angular/core";

@Component({
  selector: "faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["../../styles.marketing247.css"],
})
export class FaqsComponent {
  showAnswerQuestion = false;
  showChooseNumberQuestion = false;
  showExistingPhoneNumberQuestion = false;
  showMissedCallQuestion = false;
  showReceptionistQuestion = false;
  showRegistrationFeeQuestion = false;
  showCancelQuestion = false;
}
