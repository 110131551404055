import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Client } from "../../../interfaces/common/signUp";
import { HttpService } from "../../common/api/http.service";
@Injectable()
export class ClientService {
  getName(clientId: number): any {
    return this.api.get(`${this.apiController}/name`);
  }
  constructor(private api: HttpService) {}

  private readonly apiController: string = "client";

  get(clientId: number) {
    return this.api.get(`${this.apiController}/${clientId}`);
  }

  getBalance(): Observable<any> {
    return this.api.get(`${this.apiController}/balance`);
  }

  getCompany(): Observable<Client> {
    return this.api.get(`${this.apiController}/`);
  }

  isSuspended(): Observable<boolean> {
    return this.api.get(`${this.apiController}/suspended`);
  }

  delete(): Observable<boolean> {
    return this.api.get(`${this.apiController}/delete`);
  }
}
