import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { R247ValidationMessageComponent } from "./validation-message/validation-message.component";
import { R247TopUpInputComponent } from "./top-up-input/top-up-input.component";
import { R247CardDetailsComponent } from "./card-details-form/card-details.component";
import { CardDetailsFormBuilder } from "./card-details-form/card-details-form-builder";
import { R247AddressPickerComponent } from "./address-picker/address-picker.component";
import { AddressFormBuilder } from "./address-picker/address-form-builder";
import { R247AddressPickerListModalComponent } from "./address-picker/address-picker-list-modal.component";
import { R247TopUpFormComponent } from "./top-up-form/top-up-form.component";
import { TopUpFormBuilder } from "./top-up-form/top-up-form-builder";
import { LessThanOnePencePipe } from "./less-than-one-pence-pipe/less-than-one-pence.pipe";
import { R247NavBarComponent } from "./nav-bar/nav-bar.component";
import { LessThanTenPipe } from "./less-than-ten-pipe/less-than-ten.pipe";
import { R247DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { R247ProcessingLoaderComponent } from "./processing-loader/processing-loader.component";
import { R247ChangePasswordComponent } from "./change-password/change-password.component";
import { R247GraphWrapperComponent } from "./graph-wrapper/graph-wrapper.component";
import { ChartModule } from "angular2-chartjs";
import { R247SearchBarComponent } from "./search-bar/search-bar.component";
import { PencePerPipe } from "./pence-per-pipe/pence-per.pipe";
import { PencePipe } from "./pence-pipe/pence.pipe";
import { R247InfoIconComponent } from "./info-icon/info-icon.component";
import { StatementsScreenComponent } from "./statements/statements.component";
import { R247PillsComponent } from "./pills/pills.component";
import { R247AccountDetailPillsComponent } from "./account-detail-pills/account-detail-pills.component";

const COMPONENTS = [
  R247ValidationMessageComponent,
  R247TopUpFormComponent,
  R247TopUpInputComponent,
  R247CardDetailsComponent,
  R247AddressPickerComponent,
  R247AddressPickerListModalComponent,
  R247DateRangePickerComponent,
  R247NavBarComponent,
  R247ProcessingLoaderComponent,
  R247ChangePasswordComponent,
  R247GraphWrapperComponent,
  R247SearchBarComponent,
  R247InfoIconComponent,
  StatementsScreenComponent,
  R247PillsComponent,
  R247AccountDetailPillsComponent,
];

const PIPES = [LessThanOnePencePipe, LessThanTenPipe, PencePerPipe, PencePipe];

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbModule,
    RouterModule,
    ChartModule,
  ],
  providers: [CardDetailsFormBuilder, TopUpFormBuilder, AddressFormBuilder],
  exports: [...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ComponentsModule {}
