

import {Component, Input, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'r247-validation-message',
  template: `
      <div class="warning">
          <span class="caption text-danger" *ngIf="showMinLength"> {{ label }} is too short </span>
          <span class="caption text-danger" *ngIf="showMaxLength"> {{ label }} is too long </span>
          <span class="caption text-danger" *ngIf="showPattern"> Incorrect {{ label }} </span>
          <span class="caption text-danger" *ngIf="showRequired">Please enter {{ label }}</span>
          <span class="caption text-danger" *ngIf="showMin">Min value of {{ label }} is {{ min }}</span>
          <span class="caption text-danger" *ngIf="showMax">Max value of {{ label }} is {{ max }}</span>
          <span class="caption text-danger" *ngIf="showError">{{errorMessage}}</span>
      </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => R247ValidationMessageComponent),
      multi: true,
    },
  ],
})
export class R247ValidationMessageComponent {
  @Input()
  label: string = '';

  @Input()
  errorMessage: string = '';

  @Input()
  showError?: boolean;


  @Input()
  showRequired?: boolean;

  @Input()
  min?: number;

  @Input()
  showMin?: boolean;

  @Input()
  max?: number;

  @Input()
  showMax: boolean;

  @Input()
  minLength?: number;

  @Input()
  showMinLength?: boolean;

  @Input()
  maxLength?: number;

  @Input()
  showMaxLength?: boolean;

  @Input()
  showPattern?: boolean;
}
