import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class PasswordApi {
  private readonly apiController: string = "password";

  constructor(private api: HttpService) {}

  requestPasswordReset(email: string): Observable<boolean> {
    return this.api.post(`${this.apiController}/reset/request`, {
      email: email,
      franchiseCode: sessionStorage.getItem("seller_code"),
    });
  }

  validateResetPasswordToken(token: string): Observable<boolean> {
    return this.api.post(`${this.apiController}/reset/validate`, {
      token: token,
      franchiseCode: sessionStorage.getItem("seller_code"),
    });
  }

  resetPassword(passwordReset: any): Observable<any> {
    passwordReset.franchiseCode = sessionStorage.getItem("seller_code");

    return this.api.post(`${this.apiController}/reset`, passwordReset);
  }

  changePassword(changePassword: any): Observable<any> {
    return this.api.post(`${this.apiController}/change`, changePassword);
  }
}
