import { Injectable } from "@angular/core";
import { FileSaverService } from "ngx-filesaver";

@Injectable()
export class ExportService {
    constructor(private fileSaver: FileSaverService) {}
    exportToCsv(response: any, fileName: string) {
        this.fileSaver.save(new Blob([response], { type: 'text/csv' }), `${fileName}.csv`);
    }

    exportToPdf(response: any, fileName: string) {
        this.fileSaver.save(new Blob([response], { type: 'application/pdf' }), `${fileName}.pdf`);
    }
}