import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EMAIL_PATTERN } from "../../../@core/utils/constants";
import { PasswordService } from "../../../@core/backend/receptionist/services/pasword-service";

@Component({
  selector: "r247-request-password-page",
  styleUrls: ["./forgot-password.component.scss"],
  templateUrl: "./forgot-password.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class R247ForgotPasswordComponent implements OnInit {
  confirmCaption = "Send Password Reset Link";
  requestPasswordForm: FormGroup;
  emailSent = false;

  constructor(
    protected passwordService: PasswordService,
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected router: Router
  ) {}

  get email() {
    return this.requestPasswordForm.get("email");
  }

  ngOnInit(): void {
    this.requestPasswordForm = this.fb.group({
      email: this.fb.control("", [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
    });
  }

  requestPasswordReset(): void {
    if (!this.requestPasswordForm.valid) {
      this.requestPasswordForm.markAllAsTouched();
      return;
    }

    this.passwordService.requestPasswordReset(this.email.value).subscribe({
      next: (result) => this.onResetRequested(result),
      error: (result) => this.onResetRequested(result),
    });
  }

  onResetRequested(result: any) {
    this.confirmCaption = "Resend Password Reset Link";
    this.emailSent = true;
    this.cd.detectChanges();
  }
}
