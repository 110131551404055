<div class="change-password-form mt-5">
    <div class="alert alert-danger" *ngIf="errorMessage?.length >= 1" role="alert">  
      {{errorMessage}}
    </div>
    <div class="alert alert-success" *ngIf="success" role="alert">  
      Your password has been changed.
    </div>
    <form [formGroup]="changePasswordForm">
      <div class="card">
        <div class="card-header">
          Change Password
        </div>
        <div class="card-body">
              <div class="row mb-1">
                  <div class="col-xs-12 col-md-4">
                    <label for="input-password">Current Password:</label>
                  </div>
                  <div class="col-xs-12 col-md-8">
                    <input  id="input-password" formControlName="currentPassword" type="password" class="form-control" placeholder="Current Password">
                    <r247-validation-message [showError]="currentPassword?.errors?.required && currentPassword.touched" errorMessage="Please enter your current password"></r247-validation-message>
                  </div>
                </div>
            <div class="row mb-1">
              <div class="col-xs-12 col-md-4">
                <label for="input-password">New Password:</label>
              </div>
              <div class="col-xs-12 col-md-8">
                <input  id="input-password" formControlName="newPassword" type="password" class="form-control" placeholder="New Password">
                <r247-validation-message [showError]="newPassword?.errors?.required && newPassword.touched" errorMessage="Please enter a new password"></r247-validation-message>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <label for="input-re-password">Confirm Password:</label>
              </div>
              <div class="col-xs-12 col-md-8">
                <input  id="input-re-password" class="form-control" formControlName="confirmPassword" type="password" placeholder="Confirm Password">
                <r247-validation-message errorMessage="Please confirm your password" [showError]="confirmPassword?.errors?.required && confirmPassword.touched"></r247-validation-message>
                <r247-validation-message errorMessage="Password and confirm password does not match!" [showError]="newPassword.value != confirmPassword.value && confirmPassword.touched && newPassword.touched"></r247-validation-message> 
              </div>
            </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-primary float-right" (click)="changePassword()">Confirm</button>
        </div>
      </div>
    </form>
  </div>