

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PhoneNumbersApi } from '../api/numbers.api';

@Injectable()
export class PhoneNumbersService {

  constructor(private api: PhoneNumbersApi) { }

  getNumbers(prefix: string, category: string): any {
    return this.api.getNumbers(prefix, category);
  }

  purchaseNumber(numberToPurchase: any): Observable<any> {
    return this.api.purchaseNumber(numberToPurchase);
  }
}