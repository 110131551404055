<section class="module_imageCard_Layout">
  <div class="module_imageCard_Layout_wrapper container--max flex">
    <h1>Meet the team</h1>
    <div class="card_wrapper flex">
      <div class="text_content center">
        <div class="icon_background flex"><img class="image" src="/assets/marketing247/Blueshirt@2x.png" alt="">
        </div>
        <h2>Amber</h2>
      </div>
      <div class="text_content center">
        <div class="icon_background flex"><img class="image" src="/assets/marketing247/unnamed@2x.png" alt=""></div>
        <h2>Donna</h2>
      </div>
      <div class="text_content center">
        <div class="icon_background flex"><img class="image" src="/assets/marketing247/Yellowshirt@2x.png" alt="">
        </div>
        <h2>Jessica</h2>
      </div>
    </div>
  </div>
</section>
