<div class="row mt-2 mb-2 d-md-none">
  <div class="col-6">
    <button *ngIf="showPreviousButton" class="btn btn-block btn-secondary float-right" (click)="previousStep.emit()">Previous</button>
  </div>
  <div [ngClass]="showPreviousButton ? 'col-6' : 'col-12'">
    <button class="btn btn-block btn-primary " (click)="nextStep.emit()">{{nextCaption}}</button>
  </div>
</div>
<div class="row mt-2 mb-2 d-none d-md-block">
  <div class="col-12">
    <a routerLink="../../" class="btn btn-secondary">Exit Registration</a>
    <button class="btn btn-primary float-right ml-1" (click)="nextStep.emit()">{{nextCaption}}</button>
    <button *ngIf="showPreviousButton" class="btn btn-secondary float-right" (click)="previousStep.emit()">Previous</button>
  </div>
</div>