

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientService } from '../services/client-service';

@Injectable()
export class BalanceService {
    constructor(private clientService: ClientService) { }

    private balanceSource = new BehaviorSubject(0);
    currentBalance = this.balanceSource.asObservable();

    initialise() {
        this.clientService.getBalance().pipe().subscribe(result => {
            this.updateBlanace(result);
          });
    };

    updateBlanace(newBalance: number) {
        this.balanceSource.next(newBalance);
    };
}

