<form class="form-inline">
    <div class="form-group hidden">
      <div class="input-group">
        <input name="datepicker"
               class="form-control"
               ngbDatepicker
               type="hidden"
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="fromDate!"
               (blur)="onDateblur()"
               tabindex="-1">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
            <input #dpFromDate
                class="form-control" placeholder="yyyy-mm-dd"
                name="dpFromDate"
                [value]="formatter.format(fromDate)"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
            <input #dpToDate
                class="form-control" placeholder="yyyy-mm-dd"
                name="dpToDate"
                [value]="formatter.format(toDate)"
                (blur)="onDateblur()"
                (input)="toDate = validateInput(toDate, dpToDate.value)">
            <div class="input-group-append">
                <button class="btn btn-secondary" (click)="datepicker.toggle()" type="button" title="Click to select a date range"><i class="fas fa-calendar"></i></button>
            </div>
      </div>
    </div>
  </form>