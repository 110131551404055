<div class="modal-header">
    <h4 class="modal-title">Select Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body hp-none">
    <h1 class="text-center" *ngIf="!addresses ||(addresses && addresses.length === 0)">No Addresses Found</h1>
      <div *ngIf="addresses?.length > 0" class="list-group list-group-flush">
        <button type="button" (click)="activeModal.close(address)" class="list-group-item list-group-item-action" *ngFor="let address of addresses">{{address.text}}</button>
      </div>
  </div>