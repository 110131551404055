import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "marketing-247",
  templateUrl: "./marketing-247.component.html",
  styleUrls: ["./styles.marketing247.css"],
})
export class Marketing247Component implements OnInit {
  constructor(private router: Router) {}

  showStickyHeader = false;
  elementPosition: number = 0;

  ngOnInit(): void {}

  @ViewChild("scrollHeaderStartElm") contentStart: ElementRef;

  ngAfterViewInit() {
    this.elementPosition = this.contentStart.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    this.showStickyHeader = window.pageYOffset >= this.elementPosition;
  }

  goToRegister(selectedNumber: any) {
    return this.router.navigateByUrl(
      `/register/account?category=${selectedNumber.category}&prefix=${selectedNumber.prefix}&range=${selectedNumber.range}&number=${selectedNumber.number}`
    );
  }
}
