import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../@core/backend/receptionist/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.getRequestWithToken(req))
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status === 401)
          this.router.navigate(['auth/login']);

        return throwError(error);
      }));
  }

  private getRequestWithToken(req: HttpRequest<any>) {
    if (!this.authService.isAuthenticated())
      return req;

    const token = this.authService.getToken();

    const cloned = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${token}`)
    });

    return cloned;
  }
}
