<div class="modal-header">
    <h4 class="modal-title mb-0">Choose Your Area</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <input type="text" class="list-group-item form-control" placeholder="Type to find an area by code or name..." [(ngModel)]="prefixSearch" (ngModelChange)="searchPrefixes()" />
    <h1 class="text-center" *ngIf="!filteredPrefixes ||(filteredPrefixes && filteredPrefixes.length === 0)">No Areas Found</h1>
      <div *ngIf="filteredPrefixes?.length > 0" class="list-group list-group-flush numbers-list">
        <button type="button" (click)="activeModal.close(prefix)" class="list-group-item list-group-item-action" *ngFor="let prefix of filteredPrefixes">{{prefix.description}}</button>
      </div>
  </div>