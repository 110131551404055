import { NgModule, ModuleWithProviders } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { RegistrationRoutingModule } from "./registration-routing.module";
import { ComponentsModule } from "../@components/components.module";
import { PhoneNumbersService } from "../@core/backend/receptionist/services/phone-number.service";
import { HttpService } from "../@core/backend/common/api/http.service";
import { SignUpService } from "../@core/backend/receptionist/services/sign-up-service";
import {
  R247RegisterPaymentScreenComponent,
  R247RegisterReceptionScreenComponent,
  R247RegisterAccountScreenComponent,
  R247RegistrationSuccessComponent,
  R247RegistrationPurchaseNumberFailComponent,
  R247RegisterNumberChooserComponent,
  R247RegisterComponent,
  R247TermsAndConditionsComponent,
} from "./screens/";

import {
  R247StepperButtonsComponent,
  R247NumberChooserComponent,
} from "./components";
import { R247RegistrationComponent } from "./registration.component";
import { R247NumberListCardComponent } from "./components/number-chooser/number-list-card/number-list-card.component";
import { PricingService } from "../@core/backend/receptionist/services/pricing-service";
import { R247CostBreakdownComponent } from "./components/cost-breakdown/cost-breakdown.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { R247NumberSearchComponent } from "./components/number-chooser/number-search/number-search.component";
import { R247AreaSearchModalComponent } from "./components/number-chooser/area-search/area-search-modal.component";
import { R247MobileNumberChooserComponent } from "./components/number-chooser/mobile-number-chooser.component";
import { R247DesktopNumberChooserComponent } from "./components/number-chooser/desktop-number-chooser.component";
import { R247CategoriesModalComponent } from "./components/number-chooser/categories-modal/categories-modal.component";

const COMPONENTS = [
  R247RegistrationComponent,
  R247RegisterComponent,
  R247RegisterAccountScreenComponent,
  R247TermsAndConditionsComponent,
  R247RegisterReceptionScreenComponent,
  R247RegisterPaymentScreenComponent,
  R247RegistrationSuccessComponent,
  R247RegistrationPurchaseNumberFailComponent,
  R247StepperButtonsComponent,
  R247RegisterNumberChooserComponent,
  R247DesktopNumberChooserComponent,
  R247MobileNumberChooserComponent,
  R247NumberSearchComponent,
  R247CategoriesModalComponent,
  R247AreaSearchModalComponent,
  R247NumberChooserComponent,
  R247NumberListCardComponent,
  R247CostBreakdownComponent,
];

const EXPORT_COMPONENTS = [R247RegisterNumberChooserComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    RegistrationRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    NgbModule,
    NgSelectModule,
  ],
  exports: [...COMPONENTS],
  providers: [PhoneNumbersService, PricingService, SignUpService, HttpService],
})
export class RegistrationModule {
  static forRoot(): ModuleWithProviders<RegistrationModule> {
    return {
      ngModule: RegistrationModule,
      providers: [],
    };
  }
}
