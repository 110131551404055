import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "r247-categories-modal",
  templateUrl: "./categories-modal.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247CategoriesModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  @Input() category = "";
  categorySearch = this.category?.toLowerCase();

  @Output() categorySelected = new EventEmitter();

  @Input() categories: any[] = [];
  filteredCategories: any[] = this.categories;

  @Input() searchingCategories = true;

  ngOnInit(): void {
    this.filteredCategories = this.categories;
  }

  searchCategories() {
    var term = this.categorySearch.toLowerCase();
    this.filteredCategories =
      term.length <= 0
        ? this.categories
        : this.categories.filter((x) => x.startsWith(term));
  }
}
