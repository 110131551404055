import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { ExportApi } from "./export.api";

@Injectable()
export class FranchiseeApi {
  private readonly apiController: string = "franchisee";

  constructor(private api: HttpService, private exportApi: ExportApi) {}

  getName(franchiseeId: number): Observable<string> {
    return this.api.get(`${this.apiController}/${franchiseeId}/name`, {
      responseType: "text",
    });
  }

  countClients(): Observable<number> {
    return this.api.get(`${this.apiController}/clients/count`);
  }

  countClientsForReseller(franchiseeId: number): Observable<number> {
    return this.api.get(`${this.apiController}/${franchiseeId}/clients/count`);
  }

  countNewClientsPerMonth(franchiseeId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/${franchiseeId}/clients/new/count/monthly`
    );
  }

  countTotalClientsPerMonth(franchiseeId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/${franchiseeId}/clients/count/monthly`
    );
  }

  calculateRevenuePerClient(
    franchiseeId: number,
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.get(
      `${
        this.apiController
      }/${franchiseeId}/revenue/client?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  exportRevenuePerClient(
    franchiseeId: number,
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.exportApi.get(
      `${
        this.apiController
      }/revenue/client/export?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  calculateRevenuePerMonth(franchiseeId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/${franchiseeId}/revenue/monthly`
    );
  }

  calculateUnRealisedRevenue(franchiseeId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/${franchiseeId}/revenue/unrealised`
    );
  }

  calculateUnRealisedRevenueForReseller(
    franchiseId: number
  ): Observable<number> {
    return this.api.get(
      `${this.apiController}/${franchiseId}/revenue/unrealised`
    );
  }
}
