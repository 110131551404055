import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { ResellerService } from "../services/reseller.service";

@Injectable()
export class PricingApi {
  private readonly apiController: string = "pricing";

  constructor(
    private api: HttpService,
    private resellerService: ResellerService
  ) {}

  getMonthlyServiceCharges(): Observable<any> {
    return this.api.get(`${this.apiController}/monthly-service-charges`);
  }

  getNumberCharges(): Observable<any> {
    return this.api.get(`${this.apiController}/number-charges`);
  }

  getReceptionistCharge(): Observable<any> {
    return this.api.get(`${this.apiController}/receptionist-charge`);
  }

  get(prefix: string, category: string): Observable<any> {
    return this.api.get(
      `${this.apiController}?prefix=${prefix}&category=${category}`
    );
  }
}
