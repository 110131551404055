import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { HttpService } from "../../../@core/backend/common/api/http.service";
import { of } from "rxjs";

@Component({
  selector: "r247-marketing-highlights",
  templateUrl: "./highlights.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247MarketingHighlightsComponent implements OnInit {
  constructor(private api: HttpService) {}

  franchiseName$ = of("");

  iconOneUrl: SafeResourceUrl;
  captionOne = "Seize Every Opportunity";
  subCaptionOne =
    "According to research by AT&T, 70% of business calls are placed on hold (i.e. the call was 'answered' and placed in a queue). However, of those placed on hold  around 60% hung up";

  iconTwoUrl: SafeResourceUrl;
  captiontwo = "Freedom From Voicemail";
  subCaptiontwo =
    "Forbes report 80% of callers directed to voicemail don't leave a message.";

  iconThreeUrl: SafeResourceUrl;
  captionThree = "Be The Business Who Responds First";
  subCaptionThree =
    "Research by The Brevet Group found that, depending on sector, 30-50% of sales went to the business which responded first.";

  questionOne = "What percentage of your advertising budget is being wasted?";
  questionTwo =
    "What volume of revenue wanted to come your way but went to a competitor who answered the phone first time?";

  iconFourUrl: SafeResourceUrl;
  questionOneIconUrl: SafeResourceUrl;
  questionTwoIconUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.iconOneUrl = this.getIconPath("icon1");
    this.iconTwoUrl = this.getIconPath("icon2");
    this.iconThreeUrl = this.getIconPath("icon3");
    this.iconFourUrl = this.getIconPath("icon4");
    this.questionOneIconUrl = this.getIconPath("question1");
    this.questionTwoIconUrl = this.getIconPath("question2");

    this.franchiseName$ = this.api.get(
      `franchise/${sessionStorage.getItem("seller_code")}/name`
    );
  }

  private getIconPath(iconName: string) {
    return `${this.api.apiUrl}/assets/${sessionStorage.getItem(
      "seller_code"
    )}/${iconName}.svg`;
  }
}
