import { Injectable } from "@angular/core";
import { HttpService } from "../../common/api/http.service";
import { shareReplay } from "rxjs/operators";

@Injectable()
export class ColourService {
  constructor(private api: HttpService) {}

  public primaryColour$ = this.api
    .get(`franchise/${sessionStorage.getItem("seller_code")}/styles/primary`, {
      responseType: "text",
    })
    .pipe(shareReplay(1));
}
