import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

@Injectable()
export class AddressFormBuilder {
  constructor(private fb: FormBuilder) {}

  private addressLine1 = "";
  private addressLine2 = "";
  private addressLine3 = "";
  private city = "";
  private county = "";
  private country = "";
  private postCode = "";

  withAddressLine1(addressLine: string) {
    this.addressLine1 = addressLine;
    return this;
  }

  withAddressLine2(addressLine: string) {
    this.addressLine2 = addressLine;
    return this;
  }

  withAddressLine3(addressLine: string) {
    this.addressLine3 = addressLine;
    return this;
  }

  withCity(city: string) {
    this.city = city;
    return this;
  }

  withCounty(county: string) {
    this.county = county;
    return this;
  }

  withCountry(country: string) {
    this.country = country;
    return this;
  }

  withPostCode(postCode: string) {
    this.postCode = postCode;
    return this;
  }

  create() {
    return this.fb.group({
      addressLine1: [
        this.addressLine1,
        {
          validators: [Validators.required],
          updateOn: "blur",
        },
      ],
      addressLine2: [
        this.addressLine2,
        {
          updateOn: "blur",
        },
      ],
      addressLine3: [
        this.addressLine3,
        {
          updateOn: "blur",
        },
      ],
      city: [
        this.city,
        {
          validators: [Validators.required],
          updateOn: "blur",
        },
      ],
      county: [
        this.county,
        {
          updateOn: "blur",
        },
      ],
      country: [
        this.country,
        {
          validators: [Validators.required],
          updateOn: "blur",
        },
      ],
      postCode: [
        this.postCode,
        {
          validators: [Validators.required, Validators.minLength(6)],
          updateOn: "blur",
        },
      ],
    });
  }
}
