<div class="row">
    <div class="col-12">
        <r247-account-detail-pills></r247-account-detail-pills>
        <div class="card">
            <div class="card-header">
                Statements
            </div>
            <div class="card-body p-0 p-md-2" *ngIf="statements$ | async; let statements; else nostatements">
                <div class="row d-none d-md-block">
                    <div class="col-md-12">
                      <div class="alert alert-danger" *ngIf="error?.length >= 1" role="alert">  
                        {{error}}
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="auto-width">Date</th>
                              <th class="auto-width">Statement Number</th>
                              <th class="auto-width">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let statement of statements">
                              <td class="auto-width">{{statement.month}} {{statement.year}}</td>
                              <td class="auto-width">{{statement.id}}</td>
                              <td class="auto-width">
                                <button class="btn btn-secondary" [attr.disabled]="downloadingStatementId === statement.id ? true : null" (click)="downloadStatement(statement.id)">
                                  <span *ngIf="downloadingStatementId != statement.id">Download</span>
                                  <span *ngIf="downloadingStatementId === statement.id">Downloading...</span>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row d-block d-md-none">
                    <div class="col-12">
                      <div class="list-group list-group-flush">
                        <button *ngFor="let statement of statements" class="list-group-item list-group-item-action" [attr.disabled]="downloadingStatementId === statement.id ? true : null" (click)="downloadStatement(statement.id)">
                          <span class="font-weight-bold mr-2">{{statement.id}}</span>
                          <span>{{statement.month}}&nbsp;{{statement.year}}</span>
                          <span class="float-right" *ngIf="downloadingStatementId === statement.id">Downloading...</span>
                        </button>
                      </div>
                    </div>
                  </div>
            </div>
            <ng-template #nostatements>
              <h2 class="text-center">No Statements To Display</h2>
            </ng-template>
        </div>
    </div>
</div>