import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { signUpForm, signUpResult } from "../../../interfaces/common/signUp";
import { SignUpApi } from "../api/sign-up-api";

@Injectable()
export class SignUpService {
  getFranchiseeCaption(): Observable<string> {
    return this.api.getFranchiseeCaption();
  }
  constructor(private api: SignUpApi) {}

  checkEmail(email: string): Observable<any> {
    return this.api.checkEmail(email);
  }

  signUp(signUpForm: signUpForm): Observable<signUpResult> {
    return this.api.signUp(signUpForm);
  }

  purchaseNumber(number: any): Observable<any> {
    return this.api.purchaseNumber(number);
  }
}
