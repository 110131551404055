import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FranchiseService } from "../@core/backend/receptionist/services/franchise-service";

@Component({
  selector: "r247-registration",
  template: `
    <r247-nav-bar [showAccountLink]="false"> </r247-nav-bar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
})
export class R247RegistrationComponent implements OnInit {
  constructor(
    private franchiseService: FranchiseService,
    protected location: Location
  ) {}

  resellerName = "";
  logoImage = "";
  resellerWebsite = "#";

  ngOnInit(): void {
    this.franchiseService.get().subscribe((config) => {
      this.resellerName = config.name;
      this.logoImage = config.logo;
      this.resellerWebsite = config.website;
    });
  }

  back() {
    this.location.back();
    return false;
  }
}
