<div class="d-none d-md-flex justify-content-center text-center container">
  <div class="w-25 mx-5">
    <div>
      <img class="mb-3" height="100" width="100" [src]="iconOneUrl" />
      <h1>{{ captionOne }}</h1>
    </div>
    <p>{{ subCaptionOne }}</p>
  </div>
  <div class="w-25 mx-5">
    <div>
      <img class="mb-3" height="100" width="100" [src]="iconTwoUrl" />
      <h1>{{ captiontwo }}</h1>
    </div>
    <p>{{ subCaptiontwo }}</p>
  </div>
  <div class="w-25 mx-5">
    <div>
      <img class="mb-3" height="100" width="100" [src]="iconThreeUrl" />
      <h1>{{ captionThree }}</h1>
    </div>
    <p>{{ subCaptionThree }}</p>
  </div>
</div>
<div class="d-flex d-md-none flex-column text-center">
  <div class="my-2">
    <div>
      <img class="mb-3 mx-auto" height="100" width="100" [src]="iconOneUrl" />
      <h1>{{ captionOne }}</h1>
    </div>
    <p>{{ subCaptionOne }}</p>
  </div>
  <div class="my-2">
    <div>
      <img class="mb-3" height="100px" width="100px" [src]="iconTwoUrl" />
      <h1>{{ captiontwo }}</h1>
    </div>
    <p>{{ subCaptiontwo }}</p>
  </div>
  <div class="my-2">
    <div>
      <img class="mb-3" height="100px" width="100px" [src]="iconThreeUrl" />
      <h1>{{ captionThree }}</h1>
    </div>
    <p>{{ subCaptionThree }}</p>
  </div>
</div>
<div class="container">
  <h1 class="text-center my-5">
    <strong
      >Armed with this current information, you might want to ask yourself 2
      questions:</strong
    >
  </h1>
  <div class="d-block ml-md-5">
    <div class="d-flex">
      <img class="mr-3 mr-md-5" [src]="questionOneIconUrl" />
      <h1 class="mt-1">{{ questionOne }}</h1>
    </div>
    <div class="d-flex">
      <img class="mr-3 mr-md-5" [src]="questionTwoIconUrl" />
      <h1 class="mt-1">{{ questionTwo }}</h1>
    </div>
  </div>
  <h1 class="text-center mt-5">
    <strong
      >The best time for you to have met your
      {{ franchiseName$ | async }} receptionist would have been yesterday.
      Unfortunately we can't turn the clock back, so when for you is the next
      best time?</strong
    >
  </h1>
  <div class="text-center">
    <img class="mb-3" height="100" width="500" [src]="iconFourUrl" />
  </div>
</div>
