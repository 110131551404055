<div class="container">
  <div class="row mb-3">
    <div class="col-12">
      <h2 class="text-center">Frequently Asked Questions</h2>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <h2>Reception Service</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <action-coach-accordian [items]="receptionFaqs"></action-coach-accordian>
    </div>
  </div>
  <div class="row mt-3 mb-2">
    <div class="col-12">
      <h2>Account</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <action-coach-accordian [items]="accountFaqs"></action-coach-accordian>
    </div>
  </div>
</div>
