import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "r247-area-search-modal",
  templateUrl: "./area-search-modal.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247AreaSearchModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  @Input() prefix = "";
  prefixSearch = this.prefix?.toLowerCase();

  @Output() areaSelected = new EventEmitter();

  @Input() prefixes: any[] = [];
  filteredPrefixes: any[] = this.prefixes;

  @Input() searchingPrefixes = true;

  ngOnInit(): void {
    this.filteredPrefixes = this.prefixes;
  }

  searchPrefixes() {
    var term = this.prefixSearch.toLowerCase();
    this.filteredPrefixes =
      term.length <= 0
        ? this.prefixes
        : this.prefixes.filter(
            (x) =>
              x.code.toLowerCase().startsWith(term) ||
              x.area.toLowerCase().startsWith(term)
          );
  }
}
