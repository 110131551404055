import { Injectable } from "@angular/core";
import moment from "moment";
import { catchError, tap } from "rxjs/operators";
import { AuthApi } from "../api/auth.api";
import { FranchiseService } from "./franchise-service";
import { environment } from "../../../../../environments/environment";
import { Observable, throwError } from "rxjs";

@Injectable()
export class AuthService {
  constructor(private api: AuthApi) {}

  private TOKEN_KEY = "token";

  private EXPIRES_KEY = "expires";

  isAuthenticated() {
    var token = this.getToken();

    if (!token) return false;

    return true;

    // const expiration = localStorage.getItem(this.EXPIRES_KEY);
    // const expiresAt = JSON.parse(expiration);
    // const expiresDate = moment(expiresAt);

    // return moment().isBefore(expiresDate);
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  challengeImpersonation(token: string): Promise<string> {
    localStorage.setItem(this.TOKEN_KEY, token);

    return this.api
      .challengeImpersonation(token)
      .pipe(
        tap((result) => {
          catchError((error) => {
            localStorage.removeItem(this.TOKEN_KEY);
            return throwError(error);
          });
        })
      )
      .toPromise();
  }

  login(userName: string, password: string): Promise<any> {
    return this.api
      .login({
        userName: userName,
        password: password,
        franchiseCode: sessionStorage.getItem("seller_code"),
      })
      .pipe(
        tap((result) => {
          localStorage.setItem(this.TOKEN_KEY, result.token.access_token);
        })
      )
      .toPromise();
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
