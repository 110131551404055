import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PasswordApi } from "../api/password.api";

@Injectable()
export class PasswordService {
    constructor(private passwordApi: PasswordApi) {}
    
    requestPasswordReset(email: string): Observable<boolean> {
        return this.passwordApi.requestPasswordReset(email);
    }

    validateResetPasswordToken(token: string): Observable<boolean> {
        return this.passwordApi.validateResetPasswordToken(token);
    }

    resetPassword(passwordReset: any) {
        return this .passwordApi.resetPassword(passwordReset);
    }

    changePassword(newPassword: any) {
        return this.passwordApi.changePassword(newPassword);
    }
}