import { NgModule, ModuleWithProviders } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth.interceptor";
import { AuthGuard } from "./auth.guard";

import {
  R247LoginComponent,
  R247AuthComponent,
  NgxAuthBlockComponent,
  R247LogoutComponent,
} from "./components";

import { AuthRoutingModule } from "./auth-routing.module";
import { ComponentsModule } from "../@components/components.module";
import { HttpService } from "../@core/backend/common/api/http.service";
import { R247ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { R247ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { R247ImpersonationChallengeComponent } from "./components/impersonation/impersonation-challenge.component";

const GUARDS = [AuthGuard];
const PIPES = []; //AuthPipe];
const COMPONENTS = [
  R247LoginComponent,
  R247AuthComponent,
  R247LogoutComponent,
  R247ForgotPasswordComponent,
  R247ResetPasswordComponent,
  R247ImpersonationChallengeComponent,
  NgxAuthBlockComponent,
];

@NgModule({
  declarations: [...PIPES, ...COMPONENTS],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
  ],
  exports: [...PIPES],
  providers: [HttpService],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ...GUARDS,
      ],
    };
  }
}
