import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'r247-card-details',
  templateUrl: './card-details.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247CardDetailsComponent {
  constructor() { }
  
  get cardholderName() { return this.cardDetailsForm.get('name'); }
  get cardNumber() { return this.cardDetailsForm.get('number'); }
  get cvc() { return this.cardDetailsForm.get('cvc'); }
  get expiryMonth() { return this.cardDetailsForm.get('expiryMonth'); }
  get expiryYear() { return this.cardDetailsForm.get('expiryYear'); }
  get cardDescription() { return this.cardDetailsForm.get('cardDescription'); }
  get saveCardDetails() { return this.cardDetailsForm.get('saveCardDetails'); }

  @Input() forceSaveCardDetails = false;
  @Input() cardholderNameDefault = '';
  @Input() cardDetailsForm: FormGroup;
  @Output() cardDetailsFormChanged = new EventEmitter<FormGroup>(); 
}