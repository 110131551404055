
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordService } from '../../@core/backend/receptionist/services/pasword-service';

@Component({
  selector: 'r247-change-password',
  styleUrls: ['./change-password.component.scss'],
  templateUrl: './change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class R247ChangePasswordComponent implements OnInit {
  submitted = false;
  success = false;
  errorMessage = '';
  changePasswordForm: FormGroup;
  
  constructor(
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    private passwordService: PasswordService) { }

  ngOnInit(): void {
    const passwordValidators = [Validators.required];

    this.changePasswordForm = this.fb.group({
      currentPassword: this.fb.control('', [...passwordValidators]),
      newPassword: this.fb.control('', [...passwordValidators]),
      confirmPassword: this.fb.control('', [...passwordValidators]),
    });
  }

  get currentPassword() { return this.changePasswordForm.get('currentPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get confirmPassword() { return this.changePasswordForm.get('confirmPassword'); }

  changePassword(): void {
    this.submitted = true;
    this.errorMessage = '';


    this.passwordService.changePassword({ 
      currentPassword: this.currentPassword.value,
      newPassword: this.newPassword.value, 
      confirmPassword: this.confirmPassword.value,  
    }).subscribe((result: any) => {
      this.submitted = false;
      this.success = result.success;

      if (!result.success)
        this.errorMessage = result.errorMessage;

      this.cd.detectChanges();
    });
  }
}