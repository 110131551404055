<div *ngIf="registrationState === AWAITING_INPUT || registrationState === UNKNOWN_ERROR || registrationState === ERROR_CHECK_FAIL || registrationState === PAYMENT_FAIL || registrationState === CREATE_ACCOUNT_FAIL" class="mb-1">
  <div *ngIf="registrationState === UNKNOWN_ERROR || registrationState === ERROR_CHECK_FAIL || registrationState === PAYMENT_FAIL">
    <div class="alert alert-danger" role="alert">
      <p>{{errorMessage}}</p>
    </div>
  </div>
  <div *ngIf="registrationState === CREATE_ACCOUNT_FAIL">
    <div class="alert alert-danger" role="alert">
      <h1>Something went wrong!</h1>
      <p>We were unable to register your account.</p>
      <p>{{errorMessage}}</p>
    </div>
  </div>
  <div *ngIf="currentScreenIndex === 1">
    <r247-register-account-screen (stepComplete)="onAccountStepComplete($event)" [account]="account" [company]="company" [hasCompanyAddress]="hasCompanyAddress"></r247-register-account-screen>
  </div>
  <div *ngIf="currentScreenIndex === 2">
    <r247-register-reception-screen (previousStep)="onReceptionStepPrevious($event)" (stepComplete)="onReceptionStepComplete($event)" [reception]="reception"></r247-register-reception-screen>
  </div>
  <div *ngIf="currentScreenIndex === 3">
        <r247-terms-and-conditions  (previousStep)="previousStep()" (stepComplete)="nextStep()"></r247-terms-and-conditions>
  </div>
  <div *ngIf="currentScreenIndex === 4">
        <r247-register-payment-screen (previousStep)="onPaymentStepPrevious($event)" (stepComplete)="onPaymentStepComplete($event)" [category]="number.category" [prefix]="number.prefix" [contacts]="reception.contacts" [paymentDetails]="payment" [(billingAddress)]="billingAddress" [minimumTopUp]="minimumTopUp"></r247-register-payment-screen>
  </div>
</div>
<div *ngIf="registrationState === PROCESSING">
  <r247-processing-loader caption="Activating Reception" subCaption="We are setting up your account."></r247-processing-loader>
</div>
<div *ngIf="registrationState === SUCCESS">
  <r247-registration-success [prefix]="number.prefix" [range]="number.range" [number]="number.number" [userEmail]="account.userEmail" [password]="account.userPassword"></r247-registration-success>
</div>
<div *ngIf="registrationState === NUMBER_PURCHASE_FAIL">
    <r247-registration-purchase-number-fail [clientId]="clientId" [chooseNewNumber]="isRectifiableError" [errorMessage]="errorMessage" (numberPurchased)="repurchaseNumberSuccess($event)"></r247-registration-purchase-number-fail>
</div>