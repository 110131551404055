<form [formGroup]="cardDetailsForm">
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="username">Full Name (as appears on card)</label>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4">
        <input type="text" nbinput name="username" placeholder="Full name as it appears on your card"  required class="form-control" formControlName="name">
        <r247-validation-message errorMessage="Please enter the cardholder name" [showError]="cardholderName.errors?.required && cardholderName.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="cardNumber">Card number</label>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4">
        <input type="text" nbinput name="cardNumber" formControlName="number" (keydown.Space)="$event.preventDefault();" placeholder="Your card number" class="form-control" required>
        <r247-validation-message errorMessage="Please enter the card number" [showError]="cardNumber.errors?.required && cardNumber.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Card number is too short" [showError]="cardNumber.errors?.minlength && cardNumber.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Card number is too long" [showError]="cardNumber.errors?.maxlength && cardNumber.touched"></r247-validation-message>
      </div>
      <div class="col-xs-12 col-md-2 col-lg-2 mt-1 mt-md-0">
        <span class="text-secondary"><i class="fab fa-cc-visa fa-3x mr-2"></i></span>
        <span class="text-secondary"><i class="fab fa-cc-mastercard fa-3x"></i></span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label>Expiration</label>
      </div>
      <div class="col-xs-12 col-md-2 col-lg-2">
        <div class="input-group">
          <input nbinput type="text" placeholder="MM" name="" formControlName="expiryMonth" (keydown.Space)="$event.preventDefault();" class="form-control" required>
          <input nbinput type="text" placeholder="YY" name="" formControlName="expiryYear" (keydown.Space)="$event.preventDefault();" class="form-control" required>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="offset-md-3 offset-lg-3 col-xs-12 col-md-4 col-lg-4">
        <r247-validation-message errorMessage="Please enter the expiry month" [showError]="expiryMonth.errors?.required && expiryMonth.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Expiry month is too short" [showError]="expiryMonth.errors?.minlength && expiryMonth.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Expiry month number is too long" [showError]="expiryMonth.errors?.maxlength && expiryMonth.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Please enter the expiry year" [showError]="expiryYear.errors?.required && expiryYear.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Expiry year is too short" [showError]="expiryYear.errors?.minlength && expiryYear.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Expiry year number is too long" [showError]="expiryYear.errors?.maxlength && expiryYear.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label data-toggle="tooltip" title="Three-digits code on the back of your card">CVC</label>
      </div>
      <div class="col-xs-12 col-md-1 col-lg-1">
        <input type="text" formControlName="cvc" required class="form-control" (keydown.Space)="$event.preventDefault();">
      </div>
    </div>
    <div class="row">
      <div class="offset-md-3 offset-lg-3 col-xs-12 col-md-4 col-lg-4">
        <r247-validation-message errorMessage="Please enter the cvc" [showError]="cvc.errors?.required && cvc.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Cvc is too short" [showError]="cvc.errors?.minlength && cvc.touched"></r247-validation-message>
        <r247-validation-message errorMessage="Cvc number is too long" [showError]="cvc.errors?.maxlength && cvc.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mt-3">
      <div class="offset-md-3 offset-lg-3 col-xs-12 col-md-9 col-lg-9">
        <div class="form-check">
          <input class="form-check-input" formControlName="saveCardDetails" name="save-card-details"  type="checkbox" [attr.disabled]="forceSaveCardDetails ? true : null">
          <label class="ml-3" for="auto-top-up">Save details for future use (your details will be saved securely with Barclaycard Alias Gateway)</label>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="cardDescription">Card Description</label>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4">
        <input type="text" placeholder="Card description e.g. Accounts Credit Card"   class="form-control" formControlName="cardDescription" [attr.disabled]="!saveCardDetails.value ? true : null" [required]="saveCardDetails.value">
        <r247-validation-message errorMessage="Please enter a description of the card e.g. Accounts Credit Card" [showError]="cardDescription.errors?.required && cardDescription.touched"></r247-validation-message>
      </div>
    </div>
  </form>