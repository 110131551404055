import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { signUpForm } from "../../../interfaces/common/signUp";
import { HttpService } from "../../common/api/http.service";

@Injectable()
export class SignUpApi {
  private readonly apiController: string = "register";

  constructor(private api: HttpService) {}

  getFranchiseeCaption(): Observable<string> {
    return this.api.get(`${this.apiController}/franchisee-caption`, {
      responseType: "text",
    });
  }

  checkEmail(email: string): Observable<any> {
    return this.api.get(
      `${this.apiController}/check-user?emailAddress=${email}`
    );
  }

  signUp(signUpForm: signUpForm): any {
    return this.api.post(`${this.apiController}`, signUpForm);
  }

  purchaseNumber(number: any): any {
    return this.api.post(`${this.apiController}/purchase-number`, number);
  }
}
