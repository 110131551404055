

import { Injectable } from '@angular/core';
import { PricingApi } from '../api/pricing.api';

@Injectable()
export class PricingService {

  constructor(private api: PricingApi) { }

  getMonthlyServiceCharges(): any {
    return this.api.getMonthlyServiceCharges();
  }

  getNumberCharges(): any {
    return this.api.getNumberCharges();
  }

  getReceptionistCharge(): any {
    return this.api.getReceptionistCharge();
  }

  getPricing(prefix: string, category: string): any {
    return this.api.get(prefix, category);
  }
}