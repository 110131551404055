<div class="accordion-item">
  <div
    class="d-flex"
    style="cursor: pointer"
    role="button"
    (click)="showPanel = !showPanel">
    <div class="circle-plus closed" [ngClass]="{ opened: showPanel }">
      <div class="circle">
        <div class="horizontal"></div>
        <div class="vertical"></div>
      </div>
    </div>
    <div>
      <h4>
        <ng-content select="[question]"></ng-content>
      </h4>
    </div>
  </div>
  <div class="panel" style="max-height: 102px" *ngIf="showPanel">
    <p><ng-content select="[answer]"></ng-content></p>
  </div>
</div>
