import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Address } from "../interfaces/common/signUp";

@Injectable()
export class AddressValuesExtractor
{
    getAddress(addressForm: FormGroup): Address {
        return {
            addressLine1: addressForm.get('addressLine1').value,
            addressLine2: addressForm.get('addressLine2').value,
            addressLine3: addressForm.get('addressLine3').value,
            city: addressForm.get('city').value,
            county: addressForm.get('county').value,
            country: addressForm.get('country').value,
            postCode: addressForm.get('postCode').value
        };
    }
}