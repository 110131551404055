  <div class="d-none d-md-block">
    <div class="row mb-1">
      <div class="col-xs-12 col-md-6 text-center text-md-right">
        <h3 class="d-none d-md-block">Your Chosen Number</h3>
      </div>
      <div class="col-xs-12 col-md-6 text-center text-md-left">
        <h3 *ngIf="isNumberChosen" class="d-none d-md-block">{{selectedVirtualNumber}}</h3>
        <h3 *ngIf="!isNumberChosen" class="d-none d-md-block" style="color: rgb(156 163 175);"><em>Choose Your Number Below</em></h3>
      </div>
    </div>
    <div class="row">
      <div class="phone-number-selection-card col-xs-12 col-md-4">
        <div class="card number-card">
          <div class="card-header text-center">
            Local Numbers
          </div>
          <r247-number-list-card 
            [searchingNumbers]="searchingLocalNumbers" 
            [prefixes]="numbersTemplate.localPrefixes"
            [prefix]="numbersTemplate.defaultLocalPrefix"
            [categories]="numbersTemplate.localCategories" 
            [numbers]="localNumbers"
            (numbersSearched)="localNumbersSearched.emit($event)"
            (numberSelected)="setNumberValues($event)"
            [selectedNumber]="selectedVirtualNumber">
          </r247-number-list-card>
        </div>
      </div>
      <div class="phone-number-selection-card col-xs-12 col-md-4">
        <div class="card number-card">
          <div class="card-header text-center">
            Non Geographic Numbers
          </div>
          <r247-number-list-card 
            [searchingNumbers]="searchingNationalNumbers"
            [prefixes]="numbersTemplate.nationalPrefixes"
            [prefix]="numbersTemplate.defaultNationalPrefix" 
            [categories]="numbersTemplate.nationalCategories"
            [numbers]="nationalNumbers" 
            (numbersSearched)="nationalNumbersSearched.emit($event)"
            (numberSelected)="setNumberValues($event)"
            [selectedNumber]="selectedVirtualNumber">
          </r247-number-list-card>
        </div>
      </div>
      <div class="phone-number-selection-card col-xs-12 col-md-4">
        <div class="card number-card">
          <div class="card-header text-center">
            Freephone Numbers
          </div>
          <r247-number-list-card 
            [searchingNumbers]="searchingFreephoneNumbers"
            [prefixes]="numbersTemplate.freephonePrefixes"
            [prefix]="numbersTemplate.defaultFreephonePrefix" 
            [categories]="numbersTemplate.freephoneCategories"
            [numbers]="freephoneNumbers" 
            (numbersSearched)="freephoneNumbersSearched.emit($event)"
            (numberSelected)="setNumberValues($event)"
            [selectedNumber]="selectedVirtualNumber">
          </r247-number-list-card>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xs-12 col-sm-12 col-md-10 offset-md-1">
        <button class="btn btn-block btn-primary" [attr.disabled]="isNumberChosen ? null : true" (click)="onNumberConfirmClick()">Reserve My Phone Number</button>
      </div>
    </div>
  </div>