<h1>Pricing</h1>
<div class="row mt-3 mb-2">
    <div class="col-12">
        <h4>The cost of your Reception Service</h4>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h6 class="card-title">PA Representation</h6>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="auto-width">Call Duration</th>
                            <th class="auto-width">Charge</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="auto-width">
                                < 10 seconds</td>
                            <td class="auto-width">Free</td>
                        </tr>
                        <tr>
                            <td class="auto-width">> 10 seconds*</td>
                            <td class="auto-width">{{receptionistCharge | pence | pencePer: 'second'}}</td>
                        </tr>
                        <tr>
                            <td class="auto-width" colspan="2"><small> *Billed in 30 second increments</small></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 mb-2">
    <div class="col-xs-12 col-md-6">
        <h4>The Cost of your Number</h4>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-md-6 mb-3 mb-md-0">
        <div class="card">
            <div class="card-header">
                <h6 class="card-title">Number Rental Charges</h6>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="auto-width">Category</th>
                            <th class="auto-width">Charge</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let charge of monthlyServiceCharges">
                            <td class="auto-width">{{charge.category | categoryFriendlyName }}</td>
                            <td class="auto-width">{{charge.charge | currency: 'GBP'}} per month</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h6 class="card-title">Call Charges</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="auto-width">Prefix</th>
                                <th class="auto-width">Charge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let charge of numberCharges">
                                <td class="auto-width">{{charge.prefix}}</td>
                                <td class="auto-width">{{charge.charge | pence | pencePer: 'minute'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>