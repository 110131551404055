import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'r247-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247TermsAndConditionsComponent {
  constructor() { }
    @Output() previousStep = new EventEmitter();
    @Output() stepComplete = new EventEmitter();
}