
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { R247RegisterComponent, R247RegisterNumberChooserComponent } from './screens';
import { R247NumberChooserComponent } from './components';
import { R247RegistrationComponent } from './registration.component';

const routes: Routes = [
    {
        path: '',
        component: R247RegistrationComponent,
        children: [
            {
                path: '',
                component: R247RegisterNumberChooserComponent,
            },
            {
                path: 'number',
                component: R247RegisterNumberChooserComponent,
            },
            {
                path: 'account',
                component: R247RegisterComponent,
            },
        ],
    },
    {
        path: 'choose-number',
        component: R247NumberChooserComponent
    }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {
}
