import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EventEmitter } from "events";
import { HttpService } from "../../@core/backend/common/api/http.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { R247AddressPickerListModalComponent } from "./address-picker-list-modal.component";

@Component({
    selector: 'r247-address-picker',
    templateUrl: './address-picker.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
  })
  
  export class R247AddressPickerComponent implements OnInit {
    constructor(
        private httpService: HttpService,
        private fb: FormBuilder,
        private modalService: NgbModal) {}

    @Input() searchState = 'search';
    @Input() addressSearchLabel = 'Address';
    @Input() searchAgainLabel = 'Search Again';
    @Input() address: FormGroup;
    @Output() addressChanged = new EventEmitter();

    showAddressPicker = false;

    lookup: FormGroup;

    get postCodeLookUp() { return this.lookup.get('postCode'); }

    get addressLine1() { return this.address.get('addressLine1'); }
    get addressLine2() { return this.address.get('addressLine2'); }
    get addressLine3() { return this.address.get('addressLine3'); }
    get city() { return this.address.get('city'); }
    get county() { return this.address.get('county'); }
    get country() { return this.address.get('country'); }
    get postCode() { return this.address.get('postCode'); }

    addresses = [];

    ngOnInit(): void {
        this.lookup = this.fb.group({
            postCode: ['']
        });
    }

    enterManualAddress() {
        this.searchState = 'show-address';
        this.showAddressPicker = false;
      }
    
      searchAgain() {
        this.searchState = 'search';
        this.showAddressPicker = false;

        this.lookup.reset();
        this.address.reset();
      }

      postCodeCompanyLookup() {
        let postCode = this.lookup.get('postCode').value;
        this.httpService.get(`address/${postCode}`).pipe().subscribe(response => {
          var modal = this.modalService.open(
            R247AddressPickerListModalComponent, 
            { 
              scrollable: true, 
              size: 'lg',
            });
          modal.componentInstance.addresses = this.extractAddressFromResponse(response, postCode);
          modal.result.then(x => this.selectAddress(x));
        });
      }
    
      extractAddressFromResponse(response, postCode) {
        let addresses = [];
    
          for (let address of response.data.addresses)
          {
            let fullAddress = '';
            for (let addressLine of address.formatted_address)
            {
              if (addressLine.length > 0) 
                fullAddress += `${addressLine}, `
            }
    
            addresses.push({
              text: fullAddress.substring(0, fullAddress.length - 2),
              addressLine1: address.line_1,
              addressLine2: address.line_2,
              addressLine3: address.line_3,
              city: address.town_or_city,
              county: address.county,
              country: address.country,
              postCode: postCode.toUpperCase()
            });
          }

          return addresses;
      }

      selectAddress(address) {
        
        this.searchState = 'show-address';
        this.showAddressPicker = false;
    
        this.address.patchValue({
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          city: address.city,
          county: address.county,
          country: address.country,
          postCode: address.postCode
        });
      }
  }