import { Component, ChangeDetectionStrategy, Inject, Input, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/backend/receptionist/services/auth.service';
@Component({
  selector: 'r247-registration-success',
  templateUrl: './registration-success.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247RegistrationSuccessComponent {
  constructor(
    protected router: Router,
    private authenticationService: AuthService) { }

    @Input() prefix = '';
    @Input() range = '';
    @Input() number = '';
    @Input() userEmail = '';
    @Input() password = '';

  goToDashboard() {
    this.authenticationService.login(this.userEmail, this.password).then()
      .then((result: any) => {
        this.router.navigateByUrl(`/${result.userType}`);
      }, 
      () => this.router.navigateByUrl('/auth/login?retry=1'));
  }
}