import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AddressFormBuilder } from "../../../@components/address-picker/address-form-builder";
import { AddressValuesExtractor } from "../../../@core/utils/address-values-extractor";
import {
  EMAIL_PATTERN,
  PHONE_NUMBER_PATTERN,
} from "../../../@core/utils/constants";
import { of } from "rxjs";
import { FranchiseService } from "../../../@core/backend/receptionist/services/franchise-service";
import { SignUpService } from "../../../@core/backend/receptionist/services/sign-up-service";

@Component({
  selector: "r247-register-account-screen",
  templateUrl: "./register-account-screen.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247RegisterAccountScreenComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private addressFb: AddressFormBuilder,
    private addressValuesExtractor: AddressValuesExtractor,
    private franchiseService: FranchiseService,
    private signUpService: SignUpService
  ) {}

  get clientName() {
    return this.accountStep.get("clientName");
  }
  get clientContactNumber() {
    return this.accountStep.get("clientContactNumber");
  }
  get name() {
    return this.accountStep.get("name");
  }
  get email() {
    return this.accountStep.get("email");
  }
  get password() {
    return this.accountStep.get("password");
  }
  get companyEmail() {
    return this.accountStep.get("email");
  }

  get signUpCode() {
    return this.accountStep.get("signUpCode");
  }

  get franchisee() {
    return this.accountStep.get("franchisee");
  }

  accountStep: FormGroup;
  address: FormGroup;

  addressSearchState = "search";

  franchiseeCaption$ = of("Franchisee");

  allowFranchiseeSelection: boolean = true;

  isFranchisor$ = of(false);
  franchisees = [];
  selectedFranchisee = {};

  emailValidator = [Validators.pattern(EMAIL_PATTERN), Validators.required];
  phoneNumberValidator = [
    Validators.pattern(PHONE_NUMBER_PATTERN),
    Validators.required,
  ];

  @Input() account: any = {};
  @Input() company: any = {};
  @Input() hasCompanyAddress: boolean = false;

  @Output() stepComplete = new EventEmitter();

  ngOnInit(): void {
    this.isFranchisor$ = this.franchiseService.isFranchisor();
    this.franchiseeCaption$ = this.signUpService.getFranchiseeCaption();
    this.franchiseService.getResellers().subscribe((result: any) => {
      this.franchisees = result;
      this.franchisee.setValue(result.filter((x) => x.isHeadOffice)[0].id);
    });

    this.accountStep = this.fb.group({
      clientName: [
        this.company.name,
        {
          validators: [Validators.required, Validators.minLength(2)],
          updateOn: "blur",
        },
      ],
      clientContactNumber: [
        this.company.phoneNo,
        {
          validators: this.phoneNumberValidator,
          updateOn: "blur",
        },
      ],
      name: [
        this.account.usersName,
        {
          validators: [Validators.required, Validators.minLength(2)],
          updateOn: "blur",
        },
      ],
      email: [
        this.account.userEmail,
        {
          validators: this.emailValidator,
          updateOn: "blur",
        },
      ],
      password: [
        this.account.userPassword,
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: "blur",
        },
      ],
      signUpCode: [
        this.account.signUpCode,
        {
          validators: [],
          updateOn: "blur",
        },
      ],
      franchisee: [
        this.account.franchisee,
        {
          validators: [Validators.required],
          updateOn: "blur",
        },
      ],
    });

    this.address = this.addressFb
      .withAddressLine1(this.company.addressLine1)
      .withAddressLine2(this.company.addressLine2)
      .withAddressLine3(this.company.addressLine3)
      .withCity(this.company.city)
      .withCounty(this.company.county)
      .withCountry(this.company.country)
      .withPostCode(this.company.postCode)
      .create();

    this.addressSearchState = this.hasCompanyAddress
      ? "show-address"
      : "search";
  }

  showPassword = false;

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  validateEmailExists(e) {
    if (this.email.invalid) {
      this.franchisee.enable();
      return;
    }

    this.signUpService.checkEmail(this.email.value).subscribe((result) => {
      if (!result.exists) {
        this.franchisee.enable();
        return;
      }

      if (!result.isResellerOnly) {
        this.email.setErrors({ exists: true });

        var item = this.franchisees.filter((x) => x.id == result.companyId);

        if (item.length > 0) {
          this.franchisee.disable();
          this.franchisee.setValue(item[0].id);
        } else this.franchisee.enable();

        return;
      }

      this.franchisee.setValue(
        this.franchisees.filter((x) => x.id == result.companyId)[0].id
      );
      this.franchisee.disable();

      this.email.setErrors(null);
    });
  }

  onNextStepClick() {
    if (this.accountStep.invalid || this.address.invalid) {
      this.accountStep.markAllAsTouched();
      this.address.markAllAsTouched();
      this.addressSearchState = "show-address";
      return;
    }

    let address = this.addressValuesExtractor.getAddress(this.address);

    this.stepComplete.emit({
      clientName: this.clientName.value,
      clientContactNumber: this.clientContactNumber.value,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      city: address.city,
      county: address.county,
      country: address.country,
      postCode: address.postCode,
      name: this.name.value,
      email: this.email.value,
      password: this.password.value,
      signUpCode: this.signUpCode.value,
      franchisee: this.franchisee.value,
    });
  }
}
