import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class ExportApi {

  constructor(private api: HttpService) { } 
  
  get(url: string):Observable<any>{
    return this.api.get(url, { responseType: 'blob'});
  }
}
