<div>
    <r247-nav-bar [showAccountLink]="false">
    </r247-nav-bar>
    <div class="container-fluid">
        <div class="row" style="margin-top: -0.5rem;">
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>