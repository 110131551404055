import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SignUpService } from "../../../@core/backend/receptionist/services/sign-up-service";

@Component({
    selector: 'r247-registration-purchase-number-fail',
    templateUrl: './registration-purchase-number-fail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
  })
  export class R247RegistrationPurchaseNumberFailComponent {
    constructor(private signUpService: SignUpService) {}
    @Input() chooseNewNumber = false;
    @Input() errorMessage = '';
    @Input() clientId = 0;

    @Output() numberPurchased = new EventEmitter();

    purchaseNewNumber(numberToPurchase) {
      this.errorMessage = '';
      
      numberToPurchase.clientId = this.clientId;

      this.signUpService.purchaseNumber(numberToPurchase).subscribe(result => {
        if (result.success) {
          this.numberPurchased.emit(numberToPurchase);
          return;
        }

        this.errorMessage = result.errorMessage;
      },
      (error => {
        this.errorMessage = error.error.errorMessage;
      }));
    }
  }