<div class="row mb-1" *ngIf="searchState === 'search'">
    <div class="col-xs-12 col-md-3 col-lg-3">
      <label for="input-company-name">{{addressSearchLabel}}</label>
    </div>
    <div class="col-xs-6 col-md-9 col-lg-4">
        <form [formGroup]="lookup">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Postcode" formControlName="postCode">
              <div class="input-group-append">
                <button class="btn btn-secondary" type="button" (click)="postCodeCompanyLookup()" [disabled]="postCodeLookUp.value === ''">Search</button>
              </div>
            </div>
        </form>
    </div>
    <div class="col-xs-12 col-md-4 col-lg-3 mt-1 mt-md-0">
      <button class="btn btn-primary d-none d-md-block" (click)="enterManualAddress()">Enter Address Manually</button>
      <button class="btn btn-outline-primary d-block d-md-none" (click)="enterManualAddress()">Enter Address Manually</button>
    </div>
</div>
<div *ngIf="searchState === 'show-address'">
  <form [formGroup]="address">
    <div class="row mt-2 mb-2 d-block d-md-none">
      <div class="col-12">
        <button class="d-block d-md-none btn btn-outline-primary" (click)="searchAgain()">{{searchAgainLabel}}</button>
        <button class="d-none d-md-block btn btn-secondary" (click)="searchAgain()">{{searchAgainLabel}}</button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-street">Address Line 1</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4">
        <input type="text" class="form-control" placeholder="Address Line 1" formControlName="addressLine1" required />
        <r247-validation-message errorMessage="Plese enter the first line of your address" [showError]="addressLine1?.errors?.required && addressLine1.touched"></r247-validation-message>
      </div>
      <div class="d-none d-md-block col-md-2 col-lg-4">
        <button class="btn btn-secondary" (click)="searchAgain()">{{searchAgainLabel}}</button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-name">Address Line 2</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4 mb-1">
        <input type="text" class="form-control" placeholder="Address Line 2" formControlName="addressLine2" />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-company-name">Address Line 3</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4 mb-1">
        <input type="text" class="form-control" placeholder="Address Line 3" formControlName="addressLine3" />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-company-name">City</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4"> 
        <input type="text" class="form-control" placeholder="City" formControlName="city" required />
        <r247-validation-message errorMessage="Please enter a city" [showError]="city?.errors?.required && city.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-company-name">County</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4">
        <input type="text" class="form-control" placeholder="County" formControlName="county" required />
        <r247-validation-message errorMessage="Please enter a county" [showError]="county?.errors?.required && county.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-company-name">Country</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4">
        <input type="text" class="form-control" placeholder="Country" formControlName="country" required />
        <r247-validation-message errorMessage="Please enter a country" [showError]="country?.errors?.required && country.touched"></r247-validation-message>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xs-12 col-md-3">
        <label for="input-company-name">Postcode</label>
      </div>
      <div class="col-xs-12 col-md-7 col-lg-4">
        <input type="text" class="form-control" placeholder="Postcode" formControlName="postCode" required />
        <r247-validation-message errorMessage="Please enter a post code" [showError]="postCode?.errors?.required && postCode.touched"></r247-validation-message>
      </div>
    </div>
  </form>
</div>