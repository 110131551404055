import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "r247-address-picker-list-modal",
  templateUrl: "./address-picker-list-modal.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247AddressPickerListModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  @Input() addresses: any[];
}
