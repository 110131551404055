import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { ExportService } from "../../@core/backend/receptionist/services/export-service";
import { StatementsService } from "../../@core/backend/receptionist/services/statements-service";
import { Observable } from "rxjs";

@Component({
  selector: "r247-statements",
  templateUrl: "./statements.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StatementsScreenComponent implements OnInit {
  constructor(
    private statementsService: StatementsService,
    private exportService: ExportService
  ) {}

  statements$: Observable<[]>;
  downloadingStatementId = 0;
  error = "";

  ngOnInit(): void {
    this.statements$ = this.statementsService.getStatements();
  }

  downloadStatement(statementNumber: number): void {
    this.error = "";
    this.downloadingStatementId = statementNumber;

    this.statementsService.get(statementNumber).subscribe({
      next: (result) => {
        this.downloadingStatementId = 0;
        this.exportService.exportToPdf(result, `Statement_${statementNumber}`);
      },
      error: () => {
        this.error = `Unable to download statement ${statementNumber}.`;
        this.downloadingStatementId = 0;
      },
    });
  }
}
