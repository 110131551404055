<div class="reset-password-form">
  <div class="alert alert-success" *ngIf="emailSent">
    Please check your emails. We have sent you a link to reset your password.
  </div>
  <form [formGroup]="requestPasswordForm" (ngSubmit)="requestPasswordReset()">
  <div class="card">
    <div class="card-header">
      Forgot Password
    </div>
    <div class="card-body">
        <div class="reset-password-form">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <label for="input-email">Email address:</label>
            </div>
            <div class="col-xs-12 col-md-9">
              <input  id="input-email" class="form-control" formControlName="email" placeholder="Email address">
              <r247-validation-message [showError]="email?.hasError('pattern') && email.touched" errorMessage="Please enter a valid email address"></r247-validation-message>
              <r247-validation-message [showError]="email?.errors?.required && email.touched" errorMessage="Please enter your email address"></r247-validation-message>
            </div>
          </div>
        </div>    
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary float-right">{{confirmCaption}}</button>
    </div>
  </div>
</form>
</div>