import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Call, CallMessage } from "../../../interfaces/receptionist/reception";
import { ReceptionistApi } from "../api/receptionist-api";

@Injectable()
export class ReceptionistService {
  constructor(private api: ReceptionistApi) {}

  getMessages(startDate: Date, endDate: Date): Observable<CallMessage[]> {
    return this.api.getMessages(startDate, endDate);
  }

  exportMessages(startDate: Date, endDate: Date): any {
    return this.api.exportMessages(startDate, endDate);
  }

  getCalls(startDate: Date, endDate: Date): Observable<Call[]> {
    return this.api.getCalls(startDate, endDate);
  }

  getDailyCalls(): Observable<any> {
    return this.api.getDailyCalls();
  }

  getMonthlyCalls(): Observable<any> {
    return this.api.getMonthlyCalls();
  }

  exportCalls(startDate: Date, endDate: Date): any {
    return this.api.exportCalls(startDate, endDate);
  }

  getDailyTalkTime(): Observable<any> {
    return this.api.getDailyTalkTime();
  }

  getMonthlyTalkTime(): Observable<any> {
    return this.api.getMonthlyTalkTime();
  }
}
