import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../@core/backend/receptionist/services/auth.service";

@Component({
  selector: "r247-impersonation-challenge",
  templateUrl: "./impersonation-challenge.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class R247ImpersonationChallengeComponent implements OnInit {
  token = "";

  isValid = false;
  validatingToken = false;

  failUrl = "https://admin.callcentre.services/users?login-fail=true";

  constructor(
    protected cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    debugger;
    this.validatingToken = true;

    this.token = this.route.snapshot.queryParamMap.get("token");

    this.authService.challengeImpersonation(this.token).then(
      (result) => {
        this.validatingToken = false;
        this.isValid = true;
        this.router.navigateByUrl(`/${result}`);
      },
      (error) => {
        this.validatingToken = false;
        this.isValid = false;
        window.location.href = this.failUrl;
      }
    );
  }
}
