import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ColourService } from "../backend/receptionist/services/colour-service";

@Injectable()
export class GraphDataBuilder {
  constructor(private colourService: ColourService) {}

  build(result: any): Observable<any> {
    return this.colourService.primaryColour$.pipe(
      map((colour) => {
        return {
          labels: result.map((x: any) => x.name),
          datasets: [
            {
              backgroundColor: colour, //'#e80c30',
              data: result.map((x: any) => x.value),
            },
          ],
        };
      })
    );
  }
}
