<h2 class="text-center">How We Represent Your Business</h2>
<form [formGroup]="receptionStep">
    <div class="row">
        <div class="col-12 mb-2">
          <div class="card">
            <div class="card-header">Services</div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-xs-12 col-md-12 col-lg-12">
                  <label><strong>Your receptionist will use the following information to answer enquires.</strong></label>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-email">What services do you offer?</label>
                </div>
                <div class="col-xs-12 col-md-9">
                  <input type="text" class="form-control" formControlName="description" fullWidth  placeholder="Describe what services your company offers" required />
                  <r247-validation-message errorMessage="Please enter a description of what your business offers" [showError]="receptionSettingDescription?.errors?.required && receptionSettingDescription?.touched"></r247-validation-message>
                  <r247-validation-message errorMessage="Description is too short" [showError]="receptionSettingDescription?.errors?.minlength"></r247-validation-message>
                  <r247-validation-message errorMessage="Description is too long" [showError]="receptionSettingDescription?.errors?.maxlength"></r247-validation-message>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-xs-12 col-md-3">
                  <label for="input-opening-hours">Hours of Business</label>
                </div>
                <div class="col-xs-12 col-md-9">
                  <input type="text" class="form-control" placeholder="Hours of Business" formControlName="openingHours">
                  <r247-validation-message errorMessage="Please enter your hours of business" [showError]="receptionSettingOpeningHours?.errors?.required && receptionSettingOpeningHours?.touched"></r247-validation-message>
                </div>
              </div>
              <div class="row mb-1">
                    <div class="col-xs-12 col-md-3">
                      <label for="input-website">Website&nbsp;<small>(optional)</small></label>
                    </div>
                    <div class="col-xs-12 col-md-9 col-lg-4">
                      <input type="text" class="form-control" placeholder="Company Website" formControlName="website" autocapitalize="none">
                      <r247-validation-message errorMessage="Please enter a valid website" [showError]="receptionSettingCompanyWebsite?.errors?.pattern && receptionSettingCompanyWebsite?.touched"></r247-validation-message>
                    </div>
                </div>
                <div class="row mb-1">
                      <div class="col-xs-12 col-md-3">
                        <label for="input-company-name">Email:</label>
                      </div>
                      <div class="col-xs-12 col-md-9 col-lg-4">
                        <input type="email" class="form-control" required placeholder="Email Address customers would normally use to contact you" formControlName="email" />
                        <r247-validation-message errorMessage="Please enter a valid email address" [showError]="receptionSettingCompanyOperatingEmail?.hasError('pattern') && receptionSettingCompanyOperatingEmail.touched"></r247-validation-message>
                        <r247-validation-message errorMessage="Please enter an email address" [showError]="receptionSettingCompanyOperatingEmail?.errors?.required && receptionSettingCompanyOperatingEmail.touched"></r247-validation-message>
                      </div>
                </div>
                <div class="row mb-1">
                      <div class="col-xs-12 col-md-3">
                        <label for="input-email">Phone Number</label>
                      </div>
                      <div class="col-xs-12 col-md-9 col-lg-4">
                        <input type="tel" class="form-control" placeholder="Phone Number customers would normally use to contact you" formControlName="phoneNumber" (keydown.Space)="$event.preventDefault();">
                        <r247-validation-message errorMessage="Please enter your company phone number" [showError]="receptionSettingCompanyPhoneNumber?.errors?.required && receptionSettingCompanyPhoneNumber?.touched"></r247-validation-message>
                        <r247-validation-message errorMessage="Please enter a valid phone number" [showError]="receptionSettingCompanyPhoneNumber?.hasError('pattern') && receptionSettingCompanyPhoneNumber?.touched"></r247-validation-message>
                      </div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-12 mb-2">
              <div class="card">
                <div class="card-header">Reception</div>
                <div class="card-body">
                  <div class="row mt-3">
                    <div class="col">
                      <label>How we answer for your business?</label>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 d-none d-md-block">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Good Morning/Afternoon,&nbsp;</span>
                        </div>
                        <input type="text" class="form-control" style="width: 100%;" placeholder="Reception" formControlName="greeting" required>
                        <div class="input-group-append">
                          <span class="input-group-text">,&nbsp;[Agent Name] Speaking, How can I help?</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-block d-md-none">
                      <p><strong>Good Morning/Afternoon,</strong></p>
                      <p class="mt-2 mb-3">
                        <input type="text" class="form-control" style="width: 100%;" placeholder="Reception" formControlName="greeting" required>
                      </p>
                      <p class="d-block d-md-none">
                        <r247-validation-message errorMessage="Please enter how your receptionist should answer the phone" [showError]="receptionGreeting?.errors?.required && receptionGreeting?.touched"></r247-validation-message>
                      </p>
                      <p><strong>[Agent Name] Speaking, How can I help?</strong></p>
                      <p class="d-none d-md-block">
                        <r247-validation-message errorMessage="Please enter how your receptionist should answer the phone" [showError]="receptionGreeting?.errors?.required && receptionGreeting?.touched"></r247-validation-message>
                      </p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-md-12">
                      <label>By default your receptionist will collect the <strong>caller's name</strong>, <strong>contact number</strong>, <strong>reason for the call</strong>, would you like them to collect the <strong>caller's company name</strong>?</label>
                    </div>
                  </div>
                  <div class="row mt-3 justify-content-center">
                    <div class="col-xs-6 col-md-2">
                      <div class="form-check">
                        <input id="collect-business-name-yes" type="radio" class="custom-control-input" value="yes" formControlName="company"/>
                        <label class="custom-control-label" for="collect-business-name-yes">Yes</label>
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-2">
                      <div class="form-check">
                        <input id="collect-business-name-no" type="radio" class="custom-control-input" value="no" formControlName="company"/>
                        <label class="custom-control-label" for="collect-business-name-no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-2">
              <div class="card">
                <div class="card-header">Contacts</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-md-12 mb-1">
                      <strong>Enter the names of staff or departments that may be asked for</strong>
                      <r247-info-icon caption="‘General’ is set up by default and will always appear to ensure no messages is missed. The ability to add additional departments and/or people appears below the last created contact."></r247-info-icon>
                    </div>
                  </div>
                  <div formArrayName="contacts">
                  <div [formArrayName]="i" *ngFor="let contact of receptionContactsForm.controls; let i = index" [formGroupName]="i">
                    <form [formGroup]="contact">
                      <hr/>
                      <div class="row mb-1">
                        <div class="col-xs-12 col-md-3">
                          <label for="Name">Name of staff member or department</label>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <input class="form-control" type="text" placeholder="Name" [attr.disabled]="i === 0 ? '' : null" formControlName="name" />
                            <r247-validation-message errorMessage="Please enter a name for the contact" [showError]="receptionContactsForm.controls[i].get('name')?.errors?.required && receptionContactsForm.controls[i].get('name').touched"></r247-validation-message>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-xs-12 col-md-3">
                          <label for="Email">Email&nbsp;<small>(optional)</small></label>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                          <input class="form-control" type="email" placeholder="Email address for receiving messages" formControlName="email" />
                          <r247-validation-message errorMessage="Please enter a valid email" [showError]="receptionContactsForm.controls[i].get('email')?.hasError('pattern') && receptionContactsForm.controls[i].get('email').touched"></r247-validation-message>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4 mt-2 mt-md-0">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="sendEmail" name="sendEmail" [attr.disabled]="receptionContactsForm.controls[i].get('email')?.value?.length <=0 || receptionContactsForm.controls[i].get('email')?.hasError('pattern') ? true : null">
                            <label class="form-check-label" for="exampleCheck1">Send Message By Email</label>
                          </div>
                        </div>
                        <div class="d-none d-md-block col-md-1 col-lg-1" *ngIf="i >= 1">
                          <button (click)="deleteContact(i)" [disabled]="receptionContactsForm.controls.length <= 1" class="btn btn-secondary">X</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12 col-md-3">
                          <label for="Email">Mobile Number&nbsp;<small>(optional)</small></label>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                          <input class="form-control" type="tel" placeholder="Mobile number for receiving messages" formControlName="phoneNumber" (keydown.Space)="$event.preventDefault();" />
                          <r247-validation-message errorMessage="Please enter a valid phone number" [showError]="receptionContactsForm.controls[i].get('phoneNumber')?.hasError('pattern') && receptionContactsForm.controls[i].get('phoneNumber')?.touched"></r247-validation-message>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4 mt-2 mt-md-0">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="sendSms" name="sendSms" [attr.disabled]="receptionContactsForm.controls[i].get('phoneNumber')?.value?.length <= 0 || receptionContactsForm.controls[i].get('phoneNumber')?.hasError('pattern') ? true : null">
                            <label class="form-check-label" for="exampleCheck1">Send Message By Text <small>(10p per text message)</small></label>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="d-block d-md-none col-12" *ngIf="i >= 1">
                          <button (click)="deleteContact(i)" [disabled]="receptionContactsForm.controls.length <= 1" class="btn btn-secondary">Delete</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary float-right" (click)="addContact()">Add New Contact</button>
              </div>
          </div>
        </div>
    </div>
</form>
<r247-stepper-buttons (previousStep)="goToPreviousStep()" (nextStep)="completeStep()"></r247-stepper-buttons>