<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Registration Successful
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-3"><h1>You have sucessfully registered your account.</h1></div>
                    <div class="col-12">Pick up the phone, call your number <strong>{{prefix}} {{range}} {{number}}</strong> and say hello to your new receptionist.</div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-primary float-right" (click)="goToDashboard()">Dashboard</button>
            </div>
        </div>
    </div>
</div>