<form [formGroup]="topUpForm">
        <r247-top-up-input [minimumTopUp]="minimumTopUp" [topUpForm]="topUpForm" (amountChanged)="amountChanged($event)"></r247-top-up-input>
    <div class="row mt-3" *ngIf="allowRecurringTransaction">
        <div class="col-12 offset-md-3">
            <div class="form-check">
                <input formControlName="isAutoTopUp" name="auto-top-up"  type="checkbox" class="form-check-input" (change)="autoTopUpToggled?.emit()">
                <label class="ml-3" for="auto-top-up">Top up by this amount when my balance drops below £50</label>
            </div>
        </div>
    </div>
</form>