import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { ExportApi } from "./export.api";

@Injectable()
export class PaymentsApi {
  private readonly apiController: string = "payment";

  constructor(private api: HttpService, private exportApi: ExportApi) {}

  removeAutomatedTopUp(): Observable<any> {
    return this.api.delete(`${this.apiController}/auto-enabled/`);
  }

  getAutomatedTopUp(): Observable<any> {
    return this.api.get(`${this.apiController}/auto-enabled`);
  }

  topUp(topUp: any): any {
    return this.api.post(`${this.apiController}`, topUp);
  }

  getPayments(startDate: Date, endDate: Date): any {
    return this.api.get(
      `${
        this.apiController
      }?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`
    );
  }

  exportPayments(startDate: Date, endDate: Date): Observable<any> {
    return this.exportApi.get(
      `${
        this.apiController
      }/export?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`
    );
  }

  getPaymentCards(): Observable<any> {
    return this.api.get(`${this.apiController}/cards`);
  }

  downloadReceipt(id: number, franchiseCode: string): Observable<any> {
    return this.api.get(
      `${this.apiController}/receipt/${franchiseCode}/${id}`,
      { responseType: "blob" }
    );
  }
}
