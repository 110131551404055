export function getMode(): string {
  if (window.location.hostname.endsWith("staging.callcentre.services"))
    return MODE_STAGING;
  else if (window.location.hostname.endsWith("demo.callcentre.services"))
    return MODE_DEMO;
  else if (window.location.hostname.endsWith("callcentre.services"))
    return MODE_LIVE;
  else return MODE_UNKNOWN;
}

export const MODE_DEVELOPMENT = "development";
export const MODE_STAGING = "staging";
export const MODE_DEMO = "demo";
export const MODE_PRE_PRODUCTION = "pre-production";
export const MODE_LIVE = "live";
export const MODE_UNKNOWN = "unknown";
