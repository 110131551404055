<div id="page" class="site">
  <header id="masthead" class="site-header" [ngClass]="{'sticky': showStickyHeader }">
    <nav id="site-navigation" class="main-navigation flex container" [ngClass]="{'sticky': showStickyHeader }">
      <a href="/" class="logo">
        <img class="scrollBanner" [ngClass]="{'scroll-active': showStickyHeader }" src="assets/marketing247/scrollBanner.png" alt="247Receptionist Logo">
        <img class="normalBanner" [ngClass]="{'scroll-active': showStickyHeader }" src="assets/marketing247/logo.png" alt="247Receptionist Logo">
      </a>
      <div class="buttons">
        <a href="/register/number">Sign Up</a>
        <a href="/auth/login">Log In</a>
      </div>
    </nav>
  </header>
  <div id="content" class="site-content" #scrollHeaderStartElm>
    <div id="primary" class="content-area">
      <main id="main" class="site-main">
          <service-description></service-description>
          <meet-the-team></meet-the-team>
          <pricing></pricing>
          <section class="module_faq_Layout">
            <div class="module_faq_Layout_wrapper container flex">
              <r247-number-chooser (numberConfirmed)="goToRegister($event)"></r247-number-chooser>
            </div>
          </section> 
          <faqs></faqs>
      </main>
    </div>
  </div>
  <footer id="colophon" class="site-footer">
    <div class="footer container flex">
      <div class="privacy_wrapper flex">
        <p>totally247 Ltd. A company registered in England & Wales.</p>
        <p class="spacer">|</p>
        <p>Company number: 05830907</p>
        <p class="spacer">|</p>
        <a href="/">Terms & Conditions</a>
        <p class="spacer">|</p>
        <a href="/">Contact Us</a>
      </div>
    </div>
  </footer>
</div>
