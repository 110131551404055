import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { R247InfoIconModalComponent } from "./info-icon-modal.component";

@Component({
  selector: "r247-info-icon",
  templateUrl: "./info-icon.component.html",
  styleUrls: ["./info-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247InfoIconComponent {
  constructor(private modalService: NgbModal) {}

  @Input() caption = "";

  showInfoModal() {
    var modal = this.modalService.open(R247InfoIconModalComponent, {
      scrollable: false,
      size: "lg",
      centered: true,
    });
    modal.componentInstance.caption = this.caption;
    modal.result.then((x) => {});
  }
}
