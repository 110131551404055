<div class="d-block d-md-none">
    <div>
    <div class="my-2">
      <div class="d-flex justify-content-around">
        <button type="button" class="btn" [ngClass]="{'btn-primary': numberType === LOCAL_NUMBERS }" (click)="numberType = LOCAL_NUMBERS">Local</button>
        <button type="button" class="btn" [ngClass]="{'btn-primary': numberType === NATIONAL_NUMBERS }" (click)="numberType = NATIONAL_NUMBERS">National</button>
        <button type="button" class="btn" [ngClass]="{'btn-primary': numberType === FREEPHONE_NUMBERS }" (click)="numberType = FREEPHONE_NUMBERS">Freephone</button>
      </div>
    </div>
    <div *ngIf="numberType === LOCAL_NUMBERS">
      <r247-number-search
      [searchingNumbers]="searchingLocalNumbers"
      [prefixes]="numbersTemplate.localPrefixes"
      [prefix]="selectedLocalPrefix"
      [categories]="numbersTemplate.localCategories" 
      [category]="selectedLocalCategory"
      [numbers]="localNumbers"
      [totalNumbers]="localNumberCount"
      (previousPage)="previousLocalNumbersPage($event)" 
      (nextPage)="nextLocalNumbersPage($event)"
      (numbersSearched)="localNumbersSearched.emit($event)"
      (numberSelected)="setNumberValues($event)"
      [selectedNumber]="selectedVirtualNumber">
      </r247-number-search>
    </div>
    <div *ngIf="numberType === NATIONAL_NUMBERS">
      <r247-number-search
      [searchingNumbers]="searchingNationalNumbers"
      [prefixes]="numbersTemplate.nationalPrefixes"
      [prefix]="selectedNationalPrefix" 
      [categories]="numbersTemplate.nationalCategories"
      [category]="selectedNationalCategory"
      [numbers]="nationalNumbers" 
      [totalNumbers]="nationalNumberCount"
      (previousPage)="previousNationalNumbersPage($event)" 
      (nextPage)="nextNationalNumbersPage($event)"
      (numbersSearched)="nationalNumbersSearched.emit($event)"
      (numberSelected)="setNumberValues($event)"
      [selectedNumber]="selectedVirtualNumber">
      </r247-number-search>
    </div>
    <div *ngIf="numberType === FREEPHONE_NUMBERS">
      <r247-number-search
        [searchingNumbers]="searchingFreephoneNumbers"
        [prefixes]="numbersTemplate.freephonePrefixes"
        [prefix]="selectedFreephonePrefix" 
        [categories]="numbersTemplate.freephoneCategories"
        [category]="selectedFreephoneCategory"
        [numbers]="freephoneNumbers"
        [totalNumbers]="freephoneNumberCount"
        (previousPage)="previousFreephoneNumbersPage($event)" 
        (nextPage)="nextFreephoneNumbersPage($event)"
        (numbersSearched)="freephoneNumbersSearched.emit($event)"
        (numberSelected)="setNumberValues($event)"
        [selectedNumber]="selectedVirtualNumber">
      </r247-number-search>
    </div>
  </div>
    <div *ngIf="isNumberChosen" class="row my-1">
      <div class="my-1 col-xs-12 col-md-6 text-center text-md-right">
        <h5 class="d-block d-md-none">{{numberChosenCaption}}</h5>
      </div>
      <div class="my-1 col-xs-12 col-md-6 text-center text-md-left">
        <h5 class="d-block d-md-none">{{selectedVirtualNumber}}</h5>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 text-center text-md-left">
        <button class="my-3 btn btn-block btn-primary" [attr.disabled]="isNumberChosen ? null : true" (click)="onNumberConfirmClick()">Reserve My Phone Number</button>
      </div>
  </div>