import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { numbersTemplate } from "./number-list-card/numbers-list-template";

@Component({
  selector: "r247-mobile-number-chooser",
  templateUrl: "./mobile-number-chooser.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247MobileNumberChooserComponent implements OnChanges {
  constructor() {}

  @Input() chooseNumberCaption = "Choose Your Number";
  @Input() numberChosenCaption = "Your Chosen Number";
  @Input() reserveNumberCaption = "Reserve My Phone Number";

  private _allLocalNumbers = [];
  private _localNumbersToDisplay = [];

  get localNumberCount() {
    return this._allLocalNumbers.length;
  }
  get nationalNumberCount() {
    return this._allNationalNumbers.length;
  }
  get freephoneNumberCount() {
    return this._allFreephoneNumbers.length;
  }

  @Input()
  get localNumbers() {
    return this._localNumbersToDisplay;
  }
  set localNumbers(numbers: any[]) {
    this._allLocalNumbers = numbers;
    this._localNumbersToDisplay = [...numbers].splice(0, 5);
  }

  private _allNationalNumbers = [];
  private _nationalNumbersToDisplay = [];

  @Input()
  get nationalNumbers() {
    return this._nationalNumbersToDisplay;
  }
  set nationalNumbers(numbers: any[]) {
    this._allNationalNumbers = numbers;
    this._nationalNumbersToDisplay = [...numbers].splice(0, 5);
  }

  private _allFreephoneNumbers = [];
  private _freephoneNumbersToDisplay = [];

  @Input()
  get freephoneNumbers() {
    return this._freephoneNumbersToDisplay;
  }
  set freephoneNumbers(numbers: any[]) {
    this._allFreephoneNumbers = numbers;
    this._freephoneNumbersToDisplay = numbers.splice(0, 5);
  }

  @Input() selectedLocalCategory = numbersTemplate.localCategories[0];
  @Input() selectedNationalCategory = numbersTemplate.nationalCategories[0];
  @Input() selectedFreephoneCategory = numbersTemplate.freephoneCategories[0];

  @Output() numberConfirmed = new EventEmitter();
  @Output() localNumbersSearched = new EventEmitter<any>();
  @Output() nationalNumbersSearched = new EventEmitter<any>();
  @Output() freephoneNumbersSearched = new EventEmitter<any>();

  LOCAL_NUMBERS_ACCORDIAN_PANEL_ID = "local-numbers-accordian-panel";
  NATIONAL_NUMBERS_ACCORDIAN_PANEL_ID = "national-numbers-accordian-panel";
  FREEPHONE_NUMBERS_ACCORDIAN_PANEL_ID = "freephone-numbers-accordian-panel";

  get isNumberChosen() {
    return this.selectedVirtualNumber != "";
  }

  category = "";
  selectedVirtualNumber = "";
  prefix = "";
  range = "";
  number = "";
  numbersTemplate = numbersTemplate;

  selectedLocalPrefix = numbersTemplate.defaultLocalPrefix;
  selectedNationalPrefix = numbersTemplate.defaultNationalPrefix;
  selectedFreephonePrefix = numbersTemplate.defaultFreephonePrefix;

  LOCAL_NUMBERS = 0;
  NATIONAL_NUMBERS = 1;
  FREEPHONE_NUMBERS = 2;

  numberType = this.LOCAL_NUMBERS;

  @Input() searchingLocalNumbers = true;
  @Input() searchingNationalNumbers = true;
  @Input() searchingFreephoneNumbers = true;

  ngOnChanges(changes: SimpleChanges): void {}

  setNumberValues(numberArgs) {
    this.setSelectedValues(numberArgs);

    this.category = numberArgs.category;
    this.selectedVirtualNumber = `${numberArgs.prefix} ${numberArgs.range} ${numberArgs.number}`;

    this.prefix = numberArgs.prefix;
    this.range = numberArgs.range;
    this.number = numberArgs.number;
  }

  setSelectedValues(numberArgs) {
    switch (this.numberType) {
      case this.LOCAL_NUMBERS:
        this.selectedLocalPrefix = numberArgs.prefix;
        this.selectedLocalCategory = numberArgs.category;
        break;
      case this.NATIONAL_NUMBERS:
        this.selectedNationalPrefix = numberArgs.prefix;
        this.selectedNationalCategory = numberArgs.category;
        break;
      case this.FREEPHONE_NUMBERS:
        this.selectedFreephonePrefix = numberArgs.prefix;
        this.selectedFreephoneCategory = numberArgs.category;
        break;
    }
  }

  previousLocalNumbersPage(pageNumber) {
    this._localNumbersToDisplay = [...this._allLocalNumbers].splice(
      pageNumber * 5 - 10,
      5
    );
  }

  nextLocalNumbersPage(pageNumber) {
    this._localNumbersToDisplay = [...this._allLocalNumbers].splice(
      pageNumber * 5,
      5
    );
  }

  previousNationalNumbersPage(pageNumber) {
    this._nationalNumbersToDisplay = [...this._allNationalNumbers].splice(
      pageNumber * 5 - 10,
      5
    );
  }

  nextNationalNumbersPage(pageNumber) {
    this._nationalNumbersToDisplay = [...this._allNationalNumbers].splice(
      pageNumber * 5,
      5
    );
  }

  previousFreephoneNumbersPage(pageNumber) {
    this._freephoneNumbersToDisplay = [...this._allFreephoneNumbers].splice(
      pageNumber * 5 - 10,
      5
    );
  }

  nextFreephoneNumbersPage(pageNumber) {
    this._freephoneNumbersToDisplay = [...this._allFreephoneNumbers].splice(
      pageNumber * 5,
      5
    );
  }

  onNumberConfirmClick() {
    this.numberConfirmed.emit({
      category: this.category,
      prefix: this.prefix,
      range: this.range,
      number: this.number,
    });
  }
}
