import { Component, ChangeDetectionStrategy, Input, } from '@angular/core';

@Component({
  selector: 'r247-processing-loader',
  templateUrl: './processing-loader.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247ProcessingLoaderComponent {
  constructor() { }

  @Input() caption = '';
  @Input() subCaption = '';
}