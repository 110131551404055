import { Observable } from "rxjs";

export interface Payment {
  amount: string;
  name: string;
  cardNo: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
}

export abstract class paymentsData {
  abstract topUp(topup: any): Observable<boolean>;
  abstract getPayments(): Observable<any>;
}

export const MINIMUM_TOP_UP = 100;

export const LOW_BALANCE_THRESHOLD = 50;
