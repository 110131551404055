<div class="card">
    <div class="card-header">
        Cost Breakdown
    </div>
    <div class="card-body">
        <div class="row mb-3">
            <div class="col-12">
                The charges for a <strong>{{category}}</strong> number with a <strong>{{prefix}}</strong> prefix are:
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-xs-12 col-md-6">
                <div class="row">
                    <div class="col-xs-6 col-md-4">
                        <label>Call Charge</label>
                    </div>
                    <div class="col-6">
                        <label>{{callCharge}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-xs-12 col-md-6">
                <div class="row">
                    <div class="col-xs-6 col-md-4">
                        <label>Receptionist Charge</label>
                    </div>
                    <div class="col-6">
                        <label>{{receptionistCharge}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-xs-12 col-md-6">
                <div class="row">
                    <div class="col-xs-6 col-md-4">
                        <label>Full Number Rental Charge</label>
                    </div>
                    <div class="col-6">
                        <label>{{serviceCharge}} <small>(deducted on the 1st of every month)</small></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-xs-12 col-md-6">
                <div class="row">
                    <div class="col-xs-6 col-md-4">
                        <label>Pro-rata Number Rental This Month</label>
                    </div>
                    <div class="col-6">
                        <label>{{serviceChargeThisMonth}} <small>(deducted today)</small></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                Charges are deducted from your account balance, which can be topped up within the app. You can
                also enable an auto top up to automatically top up your account when your balance falls below £{{lowBalanceThreshold}}.
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                <div class="alert alert-info mb-0">
                    <div>* Calls are billed in 30 second increments. Calls under 10 seconds are not charged for.</div>
                    <div *ngIf="showTextCharges">* Text messages are charged at 10p per text.</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showFooter" class="card-footer">
        <button class="btn btn-primary float-right" (click)="onFooterButtonClick()" nbButton>{{footerButtonCaption}}</button>
    </div>
</div>