import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";

@Component({
  selector: "r247-account-detail-pills",
  template: '<r247-pills [pills]="pills"></r247-pills>',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247AccountDetailPillsComponent {
  pills = [
    {
      link: "../account-detail",
      caption: "Account Detail",
    },
    {
      link: "../statements",
      caption: "Statements",
    },
  ];
}
