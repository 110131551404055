import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Payment } from "../interfaces/common/signUp";

@Injectable()
export class PaymentValuesExtractor
{
    private _payment: Payment = new Payment();

    newCard(cardDetailsForm:FormGroup): PaymentValuesExtractor
    {
        this._payment.cardHolderName = cardDetailsForm.get('name').value;
        this._payment.cardNumber = cardDetailsForm.get('number').value;
        this._payment.cvc = cardDetailsForm.get('cvc').value;
        this._payment.expiryMonth = cardDetailsForm.get('expiryMonth').value;
        this._payment.expiryYear = cardDetailsForm.get('expiryYear').value;
        this._payment.saveCardDetails = cardDetailsForm.get('saveCardDetails').value;
        this._payment.cardDescription = cardDetailsForm.get('cardDescription').value;

        return this;
    }

    existingCard(cardId: number): PaymentValuesExtractor {

        this._payment.cardId = cardId;

        return this;
    }

    paymentValues(topUpForm:FormGroup) : PaymentValuesExtractor {
        this._payment.amount = topUpForm.get('amount').value;
        this._payment.createAutoTopUp = topUpForm.get('isAutoTopUp').value;
        
        return this;
    }

    getPayment(): Payment {
        let payment = new Payment(); 
        
        payment.cardId = this._payment.cardId;
        payment.cardHolderName = this._payment.cardHolderName;
        payment.cardNumber = this._payment.cardNumber;
        payment.cvc = this._payment.cvc;
        payment.expiryMonth = this._payment.expiryMonth;
        payment.expiryYear = this._payment.expiryYear;
        payment.saveCardDetails = this._payment.saveCardDetails;
        payment.cardDescription = this._payment.cardDescription;
        payment.amount = this._payment.amount;
        payment.createAutoTopUp = this._payment.createAutoTopUp;

        this._payment = new Payment();

        return payment;
    }
}