import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { ExportApi } from "../api/export.api";

@Injectable()
export class ResellerService {
  private readonly apiController: string = "reseller";

  constructor(private api: HttpService, private exportApi: ExportApi) {}

  get(resellerId: number): Observable<any> {
    return this.api.get(`${this.apiController}/${resellerId}`);
  }

  isClient(): Observable<boolean> {
    return this.api.get(`${this.apiController}/is-client`);
  }

  getOutpaymentConfig(): Observable<any> {
    return this.api.get(`${this.apiController}/outpayment/config`);
  }

  countClients(): Observable<number> {
    return this.api.get(`${this.apiController}/clients/count`);
  }

  countClientsForReseller(resellerId: number): Observable<number> {
    return this.api.get(`${this.apiController}/${resellerId}/clients/count`);
  }

  countNewClientsPerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/clients/new/count/monthly`);
  }

  countTotalClientsPerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/clients/count/monthly`);
  }

  calculateRevenuePerClient(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.get(
      `${
        this.apiController
      }/revenue/client?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  exportRevenuePerClient(periodStart: Date, periodEnd: Date): Observable<any> {
    return this.exportApi.get(
      `${
        this.apiController
      }/revenue/client/export?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  calculateRevenuePerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/revenue/monthly`);
  }

  calculateUnRealisedRevenue(): Observable<any> {
    return this.api.get(`${this.apiController}/revenue/unrealised`);
  }

  calculateUnRealisedRevenueForReseller(
    resellerId: number
  ): Observable<number> {
    return this.api.get(
      `${this.apiController}/${resellerId}/revenue/unrealised`
    );
  }

  delete(): Observable<boolean> {
    return this.api.get(`${this.apiController}/delete`);
  }
}
