import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class PhoneNumbersApi {
  private readonly apiController: string = "number";

  constructor(private api: HttpService) {}

  getNumbers(prefix: string, category: string): Observable<any> {
    return this.api.get(
      `${
        this.apiController
      }?prefix=${prefix}&category=${category}&franchiseCode=${sessionStorage.getItem(
        "seller_code"
      )}`
    );
  }

  get(id: number): any {
    return this.api.get(`${this.apiController}/${id}`);
  }

  purchaseNumber(number: any): Observable<any> {
    return this.api.post(`${this.apiController}`, number);
  }
}
