import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PasswordService } from "../../../@core/backend/receptionist/services/pasword-service";

@Component({
  selector: "r247-reset-password-page",
  styleUrls: ["./reset-password.component.scss"],
  templateUrl: "./reset-password.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class R247ResetPasswordComponent implements OnInit {
  submiting = false;
  error = "";
  resetPasswordForm: FormGroup;

  status = 0;

  token = "";

  allowReset = false;
  validatingToken = false;

  constructor(
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    private passwordService: PasswordService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.validatingToken = true;

    this.token = this.route.snapshot.queryParamMap.get("user");

    this.passwordService.validateResetPasswordToken(this.token).subscribe(
      (result) => {
        this.validatingToken = false;
        this.allowReset = result;
        this.cd.detectChanges();
      },
      (error) => {
        this.validatingToken = false;
        this.allowReset = false;
        this.cd.detectChanges();
      }
    );

    const passwordValidators = [Validators.required];

    this.resetPasswordForm = this.fb.group({
      password: this.fb.control("", [...passwordValidators]),
      confirmPassword: this.fb.control("", [...passwordValidators]),
    });
  }

  get password() {
    return this.resetPasswordForm.get("password");
  }
  get confirmPassword() {
    return this.resetPasswordForm.get("confirmPassword");
  }

  resetPassword(): void {
    this.submiting = true;

    this.passwordService
      .resetPassword({
        newPassword: this.resetPasswordForm.get("password").value,
        confirmPassword: this.resetPasswordForm.get("confirmPassword").value,
        token: this.token,
      })
      .subscribe((result: any) => {
        this.status = 1;
        this.submiting = false;
        this.cd.detectChanges();
      });
  }
}
