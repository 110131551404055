import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FranchiseeApi } from "../api/franchisee.api";

@Injectable()
export class FranchiseeService {
  getName(franchiseeId: number): Observable<string> {
    return this.api.getName(franchiseeId);
  }
  constructor(private api: FranchiseeApi) {}

  countClients(): Observable<number> {
    return this.api.countClients();
  }

  countClientsForReseller(resellerId: number) {
    return this.api.countClientsForReseller(resellerId);
  }

  countNewClientsPerMonth(franchiseeId: number): Observable<any> {
    return this.api.countNewClientsPerMonth(franchiseeId);
  }

  countTotalClientsPerMonth(franchiseeId: number): Observable<any> {
    return this.api.countTotalClientsPerMonth(franchiseeId);
  }

  calculateRevenuPerClient(
    franchiseeId: number,
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.calculateRevenuePerClient(
      franchiseeId,
      periodStart,
      periodEnd
    );
  }

  exportRevenuePerClient(
    franchiseeId: number,
    periodStart: Date,
    periodEnd: Date
  ) {
    return this.api.exportRevenuePerClient(
      franchiseeId,
      periodStart,
      periodEnd
    );
  }

  calculateUnRealisedRevenue(franchiseeId: number): Observable<number> {
    return this.api.calculateUnRealisedRevenue(franchiseeId);
  }

  calculateUnRealisedRevenueForReseller(
    franchiseeId: number
  ): Observable<number> {
    return this.api.calculateUnRealisedRevenueForReseller(franchiseeId);
  }

  calculateRevenuePerMonth(franchiseeId: number): Observable<any> {
    return this.api.calculateRevenuePerMonth(franchiseeId);
  }
}
