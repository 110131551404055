<nav class="navbar navbar-expand-lg brand-header">
    <div class="navbar-brand abs">
      <a [href]="vendorWebsite" target="_blank">
        <img [src]="logoImage" [alt]="vendorName" *ngIf="logoImage" />
      </a>
      <span class="ml-2">
        {{companyName}}
      </span>
    </div>
    <button (click)="isNavCollapsed = !isNavCollapsed" class="btn btn-primary navbar-toggler" type="button">
      <i class="fa fa-bars"></i>
    </button>
    <div [ngbCollapse]="!isNavCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav">
        <li *ngFor="let navItem of navItems" class="nav-item active">
          <a class="nav-link" (click)="isNavCollapsed = !isNavCollapsed" [routerLink]="navItem.link" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="nav-link-active">{{navItem.caption}}</a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto mr-6">
        <li *ngIf="!showAccountLink"><a class="nav-link" [href]="authenticationLink">{{authenticationButtonText}}</a></li>
        <li *ngIf="showAccountLink">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              Account
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" style="left: -73px; top: 40px;">
              <a *ngIf="showSwitchLink" ngbDropdownItem [routerLink]="switchLink.link">{{switchLink.caption}}</a>
              <a *ngIf="showAccountLink" ngbDropdownItem [routerLink]="accountLink">{{accountButtonText}}</a>
              <a ngbDropdownItem [href]="authenticationLink">{{authenticationButtonText}}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
</nav>