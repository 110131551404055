<section class="module_hero" style="background-image: url('/assets/marketing247/247R_banner_textless@2x.png')">
  <div class="module_hero_wrapper container flex">
    <h1>The right answer for your business</h1>
  </div>
</section>
<section class="module_singleText">
  <div class="module_singleText_wrapper container flex">
    <div class="text_side">
      <div class="header_div">
        <h1>We answer your calls, on your behalf, in your company name<br />
          <strong>24 hours a day, 7 days a week</strong>
        </h1>
      </div>
      <ul>
        <li style="text-align: left;">Select your new, unique business number</li>
        <li style="text-align: left;">Choose from any dial code in the country, or take advantage of a
          non-geographic or freephone number</li>
        <li style="text-align: left;">Advertise your new number as the main number for customers to call</li>
        <li style="text-align: left;">Divert an existing business number to your new number for your PA to
          answer</li>
        <li style="text-align: left;">From start to live in 5 minutes</li>
        <li style="text-align: left;">Then, introduce yourself to the newest member of your team</li>
      </ul>
    </div>
  </div>
</section>
<section class="module_singleText">
  <div class="module_singleText_wrapper container flex">
    <div class="text_side">
      <p>No contract term to tie you in,</p>
      <p>Simply credit your account with your preferred amount and top the account up as you want.</p>
      <p>If you wish, you can set up an ‘auto top up’ amount or monthly budget to make your life easier, to
        ensure you never miss a call and therefore an opportunity to do business.</p>
      <p>Your PA will send you a notification when your balance is running low</p>
    </div>
  </div>
</section>
