import { ViewportScroller } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "r247-marketing-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247MarketingHeaderComponent {
  constructor(private viewPortScroller: ViewportScroller) {}

  scrollToNumberChooser() {
    this.viewPortScroller.scrollToAnchor("number-chooser");
  }
}
