<div class="modal-header">
    <h4 class="modal-title mb-0">Choose Your Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <input type="text" class="list-group-item form-control" placeholder="Type to find a category..." [(ngModel)]="categorySearch" (ngModelChange)="searchCategories()" />
    <h1 class="text-center" *ngIf="!filteredCategories ||(filteredCategories && filteredCategories.length === 0)">No Categories Found</h1>
      <div *ngIf="filteredCategories?.length > 0" class="list-group list-group-flush numbers-list">
        <button type="button" (click)="activeModal.close(category)" class="list-group-item list-group-item-action" *ngFor="let category of filteredCategories">{{category}}</button>
      </div>
  </div>